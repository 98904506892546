/*
*	ONG Simple Lib
*	Автор RnD SoftLab (Davydov Lev aka S@DLer), 2014 - 2018.
*	Дата последнего изменения: 02.15.2018 23:07
*	Система: SimpleONG system.
*	GlobalSystemVersion: 0.9.1
*
*	Расширение по работе с формами.
*	Библиотека использует в работе плугины:
*		jQuery не ниже версии 1.7.2 (обязательно)
*		jQuery UI не ниже версии 1.11.0 (при необходимости)
*		CKeditor версии 4.3 (при необходимости)
*		Bootstrap Datapicker версии 3.3.1 (при необходимости)
*/

////////////////////////////////////////////////////////////
// Определяем рабочий объект библиотеки по работе с формами.
var ongFormHandler = 
{
	//////////////////////////////////////////
	// Обработчики метода инициализации формы.
	// Обработчики принимают обязательные параметры:
	// 		formUID 	- Идентификаторр обрабатываемой формы.
	//		elem 		- объект обрабатываемого элемента формы.
	//
	// Обработчики не возвращают никаких параметров.
	formInitHandlers:
	{
		///////////////////////////////////////
		// ОБРАБОТКА ПАРАМЕТРА "SUBMIT КНОПКА".
		submitBut: function(formUID, elem)
		{
			//////////////////////////////////////////////////
			// Проверяем, что переданы обязательные параметры:
			if (formUID && elem && document.getElementById(formUID) && typeof(elem) == 'object')
			{
				var submitConfs = ongFormHandler.serviceMethods.submitConfsInit(formUID, elem)

				$(elem).on('click', function(event) 
				{
					event = event || window.event;
					window[submitConfs.formHandlerFunc](event, formUID, submitConfs.successHandlerFunc, submitConfs.beforeHandlerFunc, submitConfs.afterHandlerFunc, submitConfs.beforeCallbackFunc); 
				});	
			}
			else
			{
				$().toastmessage('showToast', { text: 'Ошибка инициализации формы по методу "submitBut"! Не переданы основные переменные.', sticky: false, position: 'top-left', type: 'warning' });
			}
		},

		////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// ОБРАБОТКА ПАРАМЕТРА "SUBMIT KEY". Производится событие submit формы при нажатии на элементе кнопки ENTER.
		submitKey: function(formUID, elem)
		{
			//////////////////////////////////////////////////
			// Проверяем, что переданы обязательные параметры:
			if (formUID && elem && document.getElementById(formUID) && typeof(elem) == 'object')
			{
				var submitConfs = ongFormHandler.serviceMethods.submitConfsInit(formUID, elem)

				$(elem).on('keydown', function(event) 
				{
					event = event || window.event;
					
					///////////////////////////////////////////////////////////////////////////////////
					// Если была нажата кнопка ENTER, то производим инициацию передачи данных по форме.
					if (event.keyCode==13) 
					{ 
						window[submitConfs.formHandlerFunc](event, formUID, submitConfs.successHandlerFunc, submitConfs.beforeHandlerFunc, submitConfs.afterHandlerFunc, submitConfs.beforeCallbackFunc); 
					}
				});	
			}
			else
			{
				$().toastmessage('showToast', { text: 'Ошибка инициализации формы по методу "submitKey"! Не переданы основные переменные.', sticky: false, position: 'top-left', type: 'warning' });
			}
		},

		//////////////////////////////////////////////////////
		// ОБРАБОТКА ПАРАМЕТРА "ИНИЦИАЛИЗАЦИЯ CKEDITOR БЛОКА".
		ckEditorInit: function(formUID, elem)
		{
			//////////////////////////////////////////////////
			// Проверяем, что переданы обязательные параметры:
			if (formUID && elem && document.getElementById(formUID) && typeof(elem) == 'object')
			{
				///////////////////////////////////////////////////////////////////////////////////////////////////////
				// Проверяем наличие у обрабатываемого элемента настроек по идентификатору проверяемого CKEDITOR блока.
				if (typeof($(elem).data('ong_ckeditor_uid')) != 'undefined' && $(elem).data('ong_ckeditor_uid') != '') 
				{ 
					////////////////////////////////////////////////////////////////////////////////////
					// Определяем конфигурационные параметры формируемого редактора для текстового поля.
					if (typeof(ongDefaultHandler.ongCKEditorDefConfs) != 'undefined') { var editor_confs = ongDefaultHandler.ongCKEditorDefConfs; } else { var editor_confs = {}; }

					////////////////////
					// Создаем редактор.
					ongDefaultHandler.ongServiceFuncs.ongCreateCKEditor($(elem).data('ong_ckeditor_uid'), editor_confs);							
				}
			}
			else
			{
				$().toastmessage('showToast', { text: 'Ошибка инициализации формы по методу "ckEditorInit"! Не переданы основные переменные.', sticky: false, position: 'top-left', type: 'warning' });
			}
		},

		//////////////////////////////////////////////////////////
		// ОБРАБОТКА ПАРАМЕТРА "ИНИЦИАЛИЗАЦИЯ AUTO NUMERIC БЛОКА".
		autoNumericInit: function(formUID, elem)
		{
			//////////////////////////////////////////////////
			// Проверяем, что переданы обязательные параметры:
			if (formUID && elem && document.getElementById(formUID) && typeof(elem) == 'object')
			{			
				$(elem).autoNumeric('init');
			}
			else
			{
				$().toastmessage('showToast', { text: 'Ошибка инициализации формы по методу "autoNumericInit"! Не переданы основные переменные.', sticky: false, position: 'top-left', type: 'warning' });
			}
		},

		//////////////////////////////////////////////////////////////////////////////////////////////////
		// ОБРАБОТКА ЗАДАЧИ ПО ДОБАВЛЕНИЮ ВИДЖЕТА ВЫБОРА ДАТЫ ДЛЯ ЭЛЕМЕНТА НА ОСНОВЕ БИБЛИОТЕКИ JQUERY UI.
		datapickerUI: function(formUID, elem)
		{
			//////////////////////////////////////////////////
			// Проверяем, что переданы обязательные параметры:
			if (formUID && elem && document.getElementById(formUID) && typeof(elem) == 'object')
			{
				///////////////////////////////////////
				// Определяем переменные по умолчанию.
				var datepickerButtonText = 'Выберите дату';
				var datepickerButtonImg = "/static/imgs_common/icons/calendar.png";
				var datepickerFormat = "yy-mm-dd";

				/////////////////////////////////////////////////////
				// Проверяем наличие настроек по виджету выбора даты.
				if (typeof($(elem).data('ong_button_text')) != 'undefined' && $(elem).data('ong_button_text') != '') { datepickerButtonText = $(elem).data('ong_button_text'); }
				if (typeof($(elem).data('ong_button_img')) != 'undefined' && $(elem).data('ong_button_img') != '') { datepickerButtonImg = $(elem).data('ong_button_img'); }
				if (typeof($(elem).data('ong_button_format')) != 'undefined' && $(elem).data('ong_button_format') != '') { datepickerFormat = $(elem).data('ong_button_format'); }
				
				//////////////////////////////////////////////////////////////////////////////////////////////////
				// Проверяем наличие у обрабатываемой элемента необходимости добавить дату начала текущего месяца.
				if ((typeof($(elem).data('ong_set_start_date')) != 'undefined' && $(elem).data('ong_set_start_date') == 'set') || 
					(typeof($(elem).data('ong_set_stop_date')) != 'undefined' && $(elem).data('ong_set_stop_date') == 'set')) 
				{  
					var defaultDate = new Date();
					var day = String(defaultDate.getDate());
					if (day.length<2) { day = '0' + day; }
					
					////////////////////////
					// Получаем месяц и год.
					var month_num = defaultDate.getMonth() + 1;
					var month = month_num.toString();
					if(month.length < 2) {month = '0' + month;}
					var year = defaultDate.getFullYear();
					var day_end;
					
					////////////////////////////////////////////////////////////////////////////////////////////////////
					// Для того, чтобы определить количество дней в месяце нужно определить, является ли год высокосным.
					// Условно, высокосный год, каждый 4 год. В реальности высокосный год высчитывается сложней. Здесь для простоты оставлю так
					// Если год высокосный и текущий месяц Февраль, возращаем 29, иначе 28
					// Если год не высокосный и текущий месяц меньше 7, меньше августа то возращаю 31 четным. 
					// Если месяц больше 7, то возращаю 31 для не четных
					if (month_num == 2) 
					{
						if (year % 4 == 0) { day_end='29'; } else { day_end='28'; }
					} 
					else if (month_num > 7) 
					{
						if (month_num % 2 == 0) { day_end='31'; } else { day_end='30'; }                
					} 
					else 
					{
						if (month_num % 2 == 0) { day_end='30'; } else { day_end='31'; }
					}	
					
					///////////////////////////////////////////////
					// Формируем дату начала или окончания периода.
					if (typeof($(elem).data('ong_set_start_date')) != 'undefined' && $(elem).data('ong_set_start_date') == 'set')
					{
						var strDateBegin = year + '-' + month + '-' + '01';
						$(elem).val(strDateBegin);
					}
					else
					{
						var strDateEnd = year + '-' + month + '-' + day_end;
						$(elem).val(strDateEnd);
					}
				}
				
				//////////////////////////////////////////////////
				// Добавляем к эелементу формы виджет выбора даты.
				$(elem).datepicker(
				{
					showOn: "both",
					dateFormat: datepickerFormat,
					selectOtherMonths: true,
				    buttonImage: datepickerButtonImg,
					buttonImageOnly: false,
					buttonText: datepickerButtonText
				});
			}
			else
			{
				$().toastmessage('showToast', { text: 'Ошибка инициализации формы по методу "datapickerUI"! Не переданы основные переменные.', sticky: false, position: 'top-left', type: 'warning' });
			}
		},

		//////////////////////////////////////////////////////////////////////////////////////////////////
		// ОБРАБОТКА ЗАДАЧИ ПО ДОБАВЛЕНИЮ ВИДЖЕТА ВЫБОРА ДАТЫ ДЛЯ ЭЛЕМЕНТА НА ОСНОВЕ БИБЛИОТЕКИ JQUERY UI.
		datapickerBootstrap: function(formUID, elem)
		{
			//////////////////////////////////////////////////
			// Проверяем, что переданы обязательные параметры:
			if (formUID && elem && document.getElementById(formUID) && typeof(elem) == 'object')
			{
				/////////////////////////////////
				// Определяем рабочие переменные:
				var dataPickerOpts = { };
				var wrapperUID = ''; // Идентификатор обвязочного блока, в котором находится input вводимой даты.

				/////////////////////////////////////////////////////
				// Определяем конфигурацию инициализируемого виджета.
				dataPickerOpts['widgetPositioning'] = { horizontal: 'auto', vertical: 'top' };

				/////////////////////////////////////////////////////
				// Проверяем наличие настроек по виджету выбора даты.
				if (typeof($(elem).data('ong_wrapper_uid')) != 'undefined' && $(elem).data('ong_wrapper_uid') != '') { wrapperUID = $(elem).data('ong_wrapper_uid'); }
				if (typeof($(elem).data('ong_date_lang')) != 'undefined' && $(elem).data('ong_date_lang') != '') { dataPickerOpts['locale'] = $(elem).data('ong_date_lang'); } else { dataPickerOpts['locale'] = 'ru'; }
				if (typeof($(elem).data('ong_date_format')) != 'undefined' && $(elem).data('ong_date_format') != '') { dataPickerOpts['format'] = $(elem).data('ong_date_format'); }
				if (typeof($(elem).data('ong_date_use_current')) != 'undefined' && $(elem).data('ong_date_use_current') == 'off') { dataPickerOpts['useCurrent'] = false; } else { dataPickerOpts['useCurrent'] = true; }
				if (typeof($(elem).data('ong_date_show_clear')) != 'undefined' && $(elem).data('ong_date_show_clear') == 'off') { dataPickerOpts['showClear'] = false; } else { dataPickerOpts['showClear'] = true; }
				if (typeof($(elem).data('ong_date_show_today_but')) != 'undefined' && $(elem).data('ong_date_show_today_but') == 'off') { dataPickerOpts['showTodayButton'] = false; } else { dataPickerOpts['showTodayButton'] = true; }
				if (typeof($(elem).data('ong_date_min')) != 'undefined' && $(elem).data('ong_date_min') != '') { dataPickerOpts['minDate'] = $(elem).data('ong_date_min'); }
				if (typeof($(elem).data('ong_date_max')) != 'undefined' && $(elem).data('ong_date_max') != '') { dataPickerOpts['maxDate'] = $(elem).data('ong_date_max'); }

				/////////////////////////////////////////////////////////////////////////////////
				// Если данные по блоку обвязки существуют, то инициализируем виджет выбора даты.
				if (wrapperUID != '' && $('#'+wrapperUID).length > 0) 
				{
					$('#'+wrapperUID).datetimepicker( dataPickerOpts );
					
					//////////////////////////////////////////////////////////////////////////////////////////////////////////////
					// Если передан идентификатор поля выбора даты используемой в качестве минимальной даты, добавляем обработчик.
					if (typeof($(elem).data('ong_date_min_dp_id')) != 'undefined' && $(elem).data('ong_date_min_dp_id') != '') 
					{
						$('#'+wrapperUID).on("dp.change", function (e) 
						{
				            $('#'+$(elem).data('ong_date_min_dp_id')).data("DateTimePicker").minDate(e.date);
				        });
					}

					///////////////////////////////////////////////////////////////////////////////////////////////////////////////
					// Если передан идентификатор поля выбора даты используемой в качестве максимальной даты, добавляем обработчик.
					if (typeof($(elem).data('ong_date_max_dp_id')) != 'undefined' && $(elem).data('ong_date_max_dp_id') != '') 
					{
						$('#'+wrapperUID).on("dp.change", function (e) 
						{
				            $('#'+$(elem).data('ong_date_max_dp_id')).data("DateTimePicker").maxDate(e.date);
				        });
					}
				}
			}
			else
			{
				$().toastmessage('showToast', { text: 'Ошибка инициализации формы по методу "datapickerBootstrap"! Не переданы основные переменные.', sticky: false, position: 'top-left', type: 'warning' });
			}
		}
	},

	///////////////////////////////////////////////
	// Обработчики метода отправки данных по форме.
	// Обработчики принимают обязательные параметры:
	// 		formUID 	- Идентификаторр обрабатываемой формы.
	//		elem 		- ссылка на обрабатываемый элемент формы.
	//
	// Обработчики возвращают следующие параметры.
	//		extValues 	- массив дополнительно отправляемых параметров формы. Массив состоит из объектов следующего формата: { paramName: '', paramValue: ''}
	//						paramName 	- имя отправляемого параметра формы.
	//	 					paramValue 	- значение отправляемого параметра формы.	
	// 		defValue 	- флаг отправки стандартного значения параметра формы. Если 'true', то стандартное значение отправляется. Если 'false', то стандартное значение не отправляется.
	formSendHandlers:
	{
		//////////////////////////////////////////////////////////////
		// ОБРАБОТКА ПАРАМЕТРА "НЕ ПЕРЕДАВАТЬ ТЕКУЩЕЕ ЗНАЧЕНИЕ ФОРМЫ".
		noReturn: function(formUID, elem)
		{
			////////////////////////////////
			// Определяем рабочие параметры:
			var extValues 	= [],
				defValue 	= true;

			//////////////////////////////////////////////////
			// Проверяем, что переданы обязательные параметры:
			if (formUID && elem && document.getElementById(formUID) && typeof(elem) == 'object')
			{
				defValue = false;
			}
			else
			{
				$().toastmessage('showToast', { text: 'Ошибка обработки отправляемго элемента формы по методу "noReturn"! Не переданы основные переменные.', sticky: false, position: 'top-left', type: 'warning' });
			}

			////////////////////////
			// Возвращаем результат:
			return [extValues, defValue];
		},
						
		///////////////////////////////////////////////////////////////////
		// ОБРАБОТКА ПАРАМЕТРА "ЗАБРАТЬ ТЕКУЩЕЕ СОДЕРЖИМОЕ CKEDITOR БЛОКА".
		ckEditorGetData: function(formUID, elem)
		{
			////////////////////////////////
			// Определяем рабочие параметры:
			var extValues 	= [],
				defValue 	= true;

			//////////////////////////////////////////////////
			// Проверяем, что переданы обязательные параметры:
			if (formUID && elem && document.getElementById(formUID) && typeof(elem) == 'object')
			{
				///////////////////////////////////////////////////////////////////////////////////////////////////////
				// Проверяем наличие у обрабатываемого элемента настроек по идентификатору проверяемого CKEDITOR блока.
				if (typeof($(elem).data('ong_ckeditor_uid')) != 'undefined' && $(elem).data('ong_ckeditor_uid') != '') 
				{ 
					/////////////////////////////////////////////////////////////////////
					// Определяем текущий инстанс редактора и запрашиваем его содержимое.
					var editor = CKEDITOR.instances[$(elem).data('ong_ckeditor_uid')];

					if (editor) 
					{ 
						extValues.push({ paramName: elem.name, paramValue: encodeURIComponent(editor.getData()) });
						defValue = false;
					}
				}
			}
			else
			{
				$().toastmessage('showToast', { text: 'Ошибка обработки отправляемго элемента формы по методу "ckEditorGetData"! Не переданы основные переменные.', sticky: false, position: 'top-left', type: 'warning' });
			}

			////////////////////////
			// Возвращаем результат:
			return [extValues, defValue];
		},

		///////////////////////////////////////////////////////////////////////
		// ОБРАБОТКА ПАРАМЕТРА "ЗАБРАТЬ ТЕКУЩЕЕ СОДЕРЖИМОЕ AUTO NUMERIC БЛОКА".
		autoNumericGetData: function(formUID, elem)
		{
			////////////////////////////////
			// Определяем рабочие параметры:
			var extValues 	= [],
				defValue 	= true;

			//////////////////////////////////////////////////
			// Проверяем, что переданы обязательные параметры:
			if (formUID && elem && document.getElementById(formUID) && typeof(elem) == 'object')
			{
				extValues.push({ paramName: elem.name, paramValue: $('#'+formUID+' [name="'+elem.name+'"]').autoNumeric('get') });
				defValue = false;
			}
			else
			{
				$().toastmessage('showToast', { text: 'Ошибка обработки отправляемго элемента формы по методу "autoNumericGetData"! Не переданы основные переменные.', sticky: false, position: 'top-left', type: 'warning' });
			}

			////////////////////////
			// Возвращаем результат:
			return [extValues, defValue];
		}
	},

	//////////////////////////////////////////////////////////////
	// Обработчикии метода обработки результатов запроса по форме.
	// Обработчики принимают обязательные параметры:
	// 		formUID 	- Идентификаторр обрабатываемой формы.
	//
	// Обработчики не возвращают никаких параметров.	
	formResultHandlers:
	{
		///////////////////////////////////////
		// ОБРАБОТКА ПАРАМЕТРА "ОЧИСТКА ФОРМЫ".
		formClean: function(formUID)
		{
			//////////////////////////////////////////////////
			// Проверяем, что переданы обязательные параметры:
			if (formUID && document.getElementById(formUID))
			{
				$('#'+formUID)[0].reset();
			}
			else
			{
				$().toastmessage('showToast', { text: 'Ошибка пост-обработки формы по методу "formClean"! Не переданы основные переменные.', sticky: false, position: 'top-left', type: 'warning' });
			}
		},

		/////////////////////////////////////////////////////////
		// ОБРАБОТКА ПАРАМЕТРА "ЗАКРЫТЬ МОДАЛЬНОЕ ОКНО С ФОРМОЙ".
		closeFormModalWindow: function(formUID)
		{
			//////////////////////////////////////////////////
			// Проверяем, что переданы обязательные параметры:
			if (formUID && document.getElementById(formUID))
			{
				///////////////////////////////////////
				// Определяем переменные по умолчанию.
				var dialogUID = 'dialogWinBlock',
					dialogHandlerClose = 'closeDialogWinBlockStandart';

				////////////////////////////////////////////////////////////////////////////////////////
				// Если не передан UID диалогового окна, то используем стандартный UID "dialogWinBlock".
				if (typeof($('#'+formUID).data('ong_dialog_uid')) != 'undefined' && $('#'+formUID).data('ong_dialog_uid') != '') { dialogUID = $('#'+formUID).data('ong_dialog_uid'); }
					
				///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
				// Если не передано имя функции производящей закрытие модального окна, то используем значение по умолчанию - "closeDialogWinBlockStandart".
				if (typeof($('#'+formUID).data('ong_dialog_handler_close')) != 'undefined' && $('#'+formUID).data('ong_dialog_handler_close') != '') { dialogHandlerClose = $('#'+formUID).data('ong_dialog_handler_close'); }
					
				/////////////////////////////////////////////////////////////////////////////////////////////////////
				// Если запрашиваемая функции закрытия модального окна не обнаружена, то выводим сообщение об ошибке.
				if (typeof(window[dialogHandlerClose]) == 'function')
				{
					window[dialogHandlerClose](dialogUID);
				}
				else
				{
					$().toastmessage('showToast', { text: 'Не обнаружена запрашиваемая функция закрытия формы !', sticky: false, position: 'top-left', type: 'error' });
				}
			}
			else
			{
				$().toastmessage('showToast', { text: 'Ошибка пост-обработки формы по методу "closeFormModalWindow"! Не переданы основные переменные.', sticky: false, position: 'top-left', type: 'warning' });
			}
		}
	},

	////////////////////
	// СЕРВИСНЫЕ МЕТОДЫ.
	serviceMethods:
	{
		////////////////////////////////////////////////////////////////////////////////////////////////////////
		// Метод определения конфигурации инициализируемого submit события для кнопок и других элеменитов формы.
		submitConfsInit: function(formUID, elem)
		{
			///////////////////////////////////////
			// Определяем переменные по умолчанию.
			var submitConfs = 
				{
					formHandlerFunc: 'extWinInfHandlerFormRequest',
					successHandlerFunc: '',
					beforeHandlerFunc: '',
					afterHandlerFunc: '',
					beforeCallbackFunc: ''
				};

			///////////////////////////////////////////////////////////////////////////////////////////////////////
			// Если для кнопки определено значение использования глобальных настроек формы, то проверяем ихналичие.
			if (typeof($(elem).data('ong_use_global_submit_confs')) != 'undefined' && $(elem).data('ong_use_global_submit_confs') == 1)
			{
				if (typeof($('#'+formUID).data('ong_form_handler_func')) != 'undefined' && typeof(window[$('#'+formUID).data('ong_form_handler_func')]) == 'function') { submitConfs.formHandlerFunc = $('#'+formUID).data('ong_form_handler_func'); }
				if (typeof($('#'+formUID).data('ong_success_handler_func')) != 'undefined' && $('#'+formUID).data('ong_success_handler_func') != '') { submitConfs.successHandlerFunc = $('#'+formUID).data('ong_success_handler_func'); }
				if (typeof($('#'+formUID).data('ong_before_handler_func')) != 'undefined' && $('#'+formUID).data('ong_before_handler_func') != '') { submitConfs.beforeHandlerFunc = $('#'+formUID).data('ong_before_handler_func'); }
				if (typeof($('#'+formUID).data('ong_after_handler_func')) != 'undefined' && $(elem).data('ong_after_handler_func') != '') { submitConfs.afterHandlerFunc = $('#'+formUID).data('ong_after_handler_func'); }
				if (typeof($('#'+formUID).data('ong_before_callback_func')) != 'undefined' && $('#'+formUID).data('ong_before_callback_func') != '') { submitConfs.beforeCallbackFunc = $('#'+formUID).data('ong_before_callback_func'); }
			}
			else
			{
				if (typeof($(elem).data('ong_form_handler_func')) != 'undefined' && typeof(window[$(elem).data('ong_form_handler_func')]) == 'function') { submitConfs.formHandlerFunc = $(elem).data('ong_form_handler_func'); }
				if (typeof($(elem).data('ong_success_handler_func')) != 'undefined' && $(elem).data('ong_success_handler_func') != '') { submitConfs.successHandlerFunc = $(elem).data('ong_success_handler_func'); }
				if (typeof($(elem).data('ong_before_handler_func')) != 'undefined' && $(elem).data('ong_before_handler_func') != '') { submitConfs.beforeHandlerFunc = $(elem).data('ong_before_handler_func'); }
				if (typeof($(elem).data('ong_after_handler_func')) != 'undefined' && $(elem).data('ong_after_handler_func') != '') { submitConfs.afterHandlerFunc = $(elem).data('ong_after_handler_func'); }
				if (typeof($(elem).data('ong_before_callback_func')) != 'undefined' && $(elem).data('ong_before_callback_func') != '') { submitConfs.beforeCallbackFunc = $(elem).data('ong_before_callback_func'); }
			}

			//////////////////////////////////////
			// Возвращаем полученную конфигурацию:
			return submitConfs;
		}
	}
}

///////////////////////////////
// Функция инициализации формы.
// Принимает следующие параметры:
// 		formUID 		- идентификатор обрабатываемой формы
function extWinInfHandlerFormInitialize(formUID)
{
	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если не передан идентификатор формы, то возвращаем сообщение об ошибке и завершаем работу обработчика.
	if (typeof(formUID) == 'undefined' || formUID == '')
	{
		/////////////////////////////////////////
		// Выводим сообщение в виде silent блока.
		$().toastmessage('showToast', { text: 'Не передан идентификатор формы для инициализации!', sticky: false, position: 'top-left', type: 'error' }); return false;
	}

	///////////////////////////////////////
	// Проверяем наличие формы на странице.
	if ($('#'+formUID).length > 0)
	{
		///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// Определяем имя переменной основной библиотеки, по которой производится проверка завершения инициализации основных библиотек.
		var handlerInitCheck = $('#'+formUID).data('handler_init_check') || 'ongHandlerInitFlag_default';

		//////////////////////////////////////////////////////////////////////////////////////
		// Запускаем проверку успешности инициализации запрошенной на отслеживание библиотеки.
		var nTimer = setInterval(function() 
		{
			if (window.ongDefaultHandler && window.ongDefaultHandler[handlerInitCheck]) 
			{
				//////////////////////////////////////////////////////
				// Определяем ссылку на элементы обрабатываемой формы.
				var formElements = document.getElementById(formUID).elements;

				/////////////////////////////////////////
				// Производим обход всех элементов формы:
				for (var i = 0; i < formElements.length; i++)
				{
					///////////////////////////////////////////////////////////
					// Определяем наличие конфигурационного параметра элемента.
					if (typeof($(formElements[i]).data('ong_confs_init')) != 'undefined' && $(formElements[i]).data('ong_confs_init') != '')
					{					
						////////////////////////////////////////////////////////////////////////////////////
						// Определяем список конфигурационных параметров элемента и производим их обработку.
						var elemConfsList = $(formElements[i]).data('ong_confs_init').split(';');
						for (var iConf = 0; iConf < elemConfsList.length; iConf++)
						{
							/////////////////////////////////////////////////////////////////////////////////////////////////////////////
							// Проверяем наличие запрошенного обработчика инициализации в рабочем объекте библиотеки по работе с формами.
							if (typeof(ongFormHandler.formInitHandlers[elemConfsList[iConf]]) == 'function')
							{
								ongFormHandler.formInitHandlers[elemConfsList[iConf]]( formUID, formElements[i] );
							}
							else
							{
								$().toastmessage('showToast', { text: 'Ошибка инициализации формы по методу "'+elemConfsList[iConf]+'"! Не обнаружен запрашиваемый обработчик.', sticky: false, position: 'top-left', type: 'warning' });
							}
						}
					}
				}

				////////////////////
				// Очищаем интервал.
				clearInterval(nTimer);
			}
		}, 40);
	}
	else
	{
		/////////////////////////////////////////
		// Выводим сообщение в виде silent блока.
		$().toastmessage('showToast', { text: 'Запрошенная на инициализацию форма не обнаружена!', sticky: false, position: 'top-left', type: 'error' }); return false;		
	}
}

///////////////////////////////////////////////////////////////////////
// Функция отправки данных по форме стандартными средствами javascript.
// Функция принимает следующие параметры:
//		event 					- событие вызвавшее эту функцию или значение false.
// 		formUID 				- идентификатор обрабатываемой формы
//		successHandlerFunc 		- функция запускаемая по успешному завершению передачи данных по форме
//		beforeHandlerFunc 		- функция запускаемая перед началом передачи данных по форме
//		afterHandlerFunc 		- функция запускаемая после окончания передачи данных по форме
//		beforeCallbackFunc 		- функция запускаемая перед началом обработки формы.
function extWinInfHandlerFormRequest(event, formUID, successHandlerFunc, beforeHandlerFunc, afterHandlerFunc, beforeCallbackFunc)
{
	event = event || window.event;

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Хак для IE. Если отсутсвует объект target, но присутствует объект srcElement, то копируем объект srcElement в объект target.
	if (typeof(event.target) != 'object' && typeof(event.srcElement) == 'object') { event.target = event.srcElement; }

	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если не передан идентификатор формы, то возвращаем сообщение об ошибке и завершаем работу обработчика.
	if (typeof(formUID) == 'undefined' || formUID == '' || formUID == null)
	{
		/////////////////////////////////////////
		// Выводим сообщение в виде silent блока.
		$().toastmessage('showToast', { text: 'Не передан идентификатор обрабатываемой формы!', sticky: false, position: 'top-left', type: 'error' }); return false;
	}	

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если передана функция запускаемая перед началом обработки формы, то производим ее запуск и в зависимости от возвращенного ею ответа
	// производим либо отмену обработки формы, либо продолжение обработки формы.
	if (typeof(beforeCallbackFunc) != 'undefined' && beforeCallbackFunc != '' && beforeCallbackFunc != null && typeof(window[beforeCallbackFunc]) == 'function')
	{
		var callbackRes = window[beforeCallbackFunc]( formUID );
		if (callbackRes == false) { return false; }
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если текущая кнопка имеет параметр "disabled", то пропускаем обработку запроса на отправку формы.
	// Если это параметр отсутствует, то добавляем его, блокируя таким образом неосторожную отправку формы несколько раз до получения ответа от сервера.
	if (typeof(event.target) == 'object')
	{
		if (event.target.disabled) { return false; } else { event.target.disabled = true; }
	}

	///////////////////////////////////////
	// Проверяем наличие формы на странице.
	if ($('#'+formUID).length > 0)
	{
		//////////////////////////////////////////////
		// Определяем используемыефункции-обработчики.
		if (typeof(successHandlerFunc) == 'undefined' || successHandlerFunc == '' || successHandlerFunc == null) { var successHandlerFunc = 'ongDefaultRequestResultShow'; }
		if (typeof(beforeHandlerFunc) == 'undefined' || beforeHandlerFunc == '' || beforeHandlerFunc == null) { var beforeHandlerFunc = 'ongActivateAjaxIcon'; }
		if (typeof(afterHandlerFunc) == 'undefined' || afterHandlerFunc == '' || afterHandlerFunc == null) { var afterHandlerFunc = 'ongDeactivateAjaxIcon'; }	

		////////////////////////////////////////////////////////////
		// Определяем основной массив с отправляемыми данными формы.
		var sent_data = new FormData();

		//////////////////////////////////////////////////////
		// Определяем ссылку на элементы обрабатываемой формы.
		var formElements = document.getElementById(formUID).elements;

		/////////////////////////////////////////
		// Производим обход всех элементов формы:
		for (var i = 0; i < formElements.length; i++)
		{
			//////////////////////////////////////////////////////////
			// Флаг возвращения значения параметра формы по умолчанию.
			var elemReturnDefaultFlag = true; 

			///////////////////////////////////////////////////////////////////////
			// Предварительно производится проверка обрабатываемого элемента формы.
			// Если у элемента формы не определены параметры имени и/или типа элемента, то пропускаем обработку такого элемента.
			// Если элемент формы является кнопкой и отсутствует событие соответствующее нажатию этой кнопки, то пропускаем обработку такого элемента.
			// Если элемент формы является чекбоксом или радиокнопкой, то проверяем был ли этот чекбокс или радиокнопка отмечен как выделенный. Если нет, то дополнительно проверяем наличие
			// у такого элемента формы data-параметра "data-ong_return". Если такого параметра нет и он не установлен в значение 'always', то также пропускаем обработку такого элемента.
			if (!formElements[i].name || !formElements[i].type || (typeof($(formElements[i]).data('ong_return')) != 'undefined' && $(formElements[i]).data('ong_return') == 'never'))
			{
				continue;
			}
			else if (formElements[i].type == 'button' || formElements[i].type == 'reset' || formElements[i].type == 'submit')
			{
				/////////////////////////////
				// Определяем объект события.
				if (event && typeof(event) == 'object') 
				{
					//////////////////////////////////////////////////////////
					// Определяем, что у события присутствует объект 'target'.
					if (typeof(event.target) == 'object')
					{
						/////////////////////////////////////////////////////////////////
						// Проверяем, что событие было вызвано по нажатию текущей кнопки.
						if (event.target !== formElements[i]) 
						{
							continue; 
						}
					}
					else
					{
						continue;
					}
				}
				else
				{
					continue;
				}
			}			
			else if (formElements[i].type == 'checkbox' || formElements[i].type == 'radio')
			{
				if (formElements[i].checked == false)
				{
					if (typeof($(formElements[i]).data('ong_return')) == 'undefined' || $(formElements[i]).data('ong_return') != 'always')
					{
						continue;						
					}
				}
			}

			///////////////////////////////////////////////////////////////
			// Определяем наличие параметра с конифгурационными действиями.
			if (typeof($(formElements[i]).data('ong_confs_exec')) != 'undefined' && $(formElements[i]).data('ong_confs_exec') != '')
			{
				////////////////////////////////////////////////////////////////////////////////////
				// Определяем список конфигурационных параметров элемента и производим их обработку.
				var elemConfsList = $(formElements[i]).data('ong_confs_exec').split(';');
				for (var iConf = 0; iConf < elemConfsList.length; iConf++)
				{
					///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
					// Проверяем наличие запрошенного обработчика формирования данных на отправку в рабочем объекте библиотеки по работе с формами.
					if (typeof(ongFormHandler.formSendHandlers[elemConfsList[iConf]]) == 'function')
					{
						//////////////////////////////////////////////////////////////
						// Производим запрос к методу формирования данных на отправку.
						var extParamsResult = ongFormHandler.formSendHandlers[elemConfsList[iConf]]( formUID, formElements[i] );

						///////////////////////////////////////////////////////////////////////////////
						// Если массив сформированных на отправку параметров не пуст, то заполняем его.
						if (extParamsResult[0] && extParamsResult[0].length > 0)
						{
							for (var iParam = 0; iParam < extParamsResult[0].length; iParam++)
							{
								sent_data.append(extParamsResult[0][iParam].paramName, extParamsResult[0][iParam].paramValue);
							}
						}

						////////////////////////////////////////////////////////////////////////////
						// Определяем необходимость возвращения стандартных значений элемента формы.
						elemReturnDefaultFlag = extParamsResult[1];
					}
					else
					{
						$().toastmessage('showToast', { text: 'Ошибка подготовки элемента формы к отправке по методу "'+elemConfsList[iConf]+'"! Не обнаружен запрашиваемый обработчик.', sticky: false, position: 'top-left', type: 'warning' });
					}
				}
			}

			/////////////////////////////////////////////////////////////////////////////////////////////////
			// По умолчанию добавляем в массив отправляемых значений название эелемента формы и его значение.
			// Значение по умолчанию формируется в зависимости от типа обрабатываемого элемента формы.
			if (elemReturnDefaultFlag) 
			{ 
				if (formElements[i].type == 'checkbox')
				{
					sent_data.append(formElements[i].name, formElements[i].checked ? formElements[i].value : 0);
				}
				else if (formElements[i].type == 'select-multiple')
				{
					var selectedValues = [];
    				for (var iSel = 0; iSel < formElements[i].length; iSel++) 
    				{
        				if (formElements[i].options[iSel].selected) selectedValues.push(formElements[i].options[iSel].value);
        			}
        			sent_data.append(formElements[i].name, selectedValues.join('::')); 
				}
				else if (formElements[i].type == 'file')
				{
					if (typeof(formElements[i].files) == 'object' && formElements[i].files.length > 0)
					{
						for (iFile = 0; iFile < formElements[i].files.length; iFile++)
						{
							sent_data.append(formElements[i].name, formElements[i].files[iFile]); 
						}
					}
				}
				else
				{
					sent_data.append(formElements[i].name, encodeURIComponent(formElements[i].value)); 
				}
			}
		}

		////////////////////////////////////////////////////
		// Определяем дополнительные параметры для передачи.
		sent_data.append('curr_form_id', encodeURIComponent(formUID));
		sent_data.append('isAjaxRequest', 1);

		//////////////////////////////
		// Инициализируем AJAX-запрос.
		var xhr = new XMLHttpRequest();
        xhr.open($('#'+formUID).attr('method'), $('#'+formUID).attr('action'), true);
        
        //////////////////////////////////////////////////////////
        // Определяем событие завершения загрузки данных по форме.
        xhr.onload = function(e) 
        {
        	//////////////////////////////////////////
        	// Производим отключение блокировки кнопки.
			if (typeof(event.target) == 'object') { event.target.disabled = false; }        	

	        ///////////////////////////////////////////////////////////////////////////////////
	        // Производим запуск функции исполняемой после завершения отправки данных по форме.
	        ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder.apply(null, ['complete', afterHandlerFunc]);

            if(this.status == 200) 
            {
            	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            	// Производим попытку преобразовать JSON ответ в объект. Если это сделать не удалось, то выводим сообзение об ошибке.
            	try
            	{
            		var requestResult = JSON.parse(e.currentTarget.responseText);
            	}
            	catch(e)
            	{
            		console.log(e);
            		showErrorRedirect('', '', e);
            		return false;
            	}

            	////////////////////////////////////////////////////
            	// Производим поиск обработчика результатов запроса.
				ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder.apply(null, ['success', successHandlerFunc, requestResult]);
            }
            else
            {
				showErrorRedirect('', '', e.currentTarget.responseText);
            }
        }
        
        /////////////////////////////////////////////////////////////////////////
        // Производим запуск функции исполняемой перед отправкой данных по форме.
        ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder.apply(null, ['beforeSend', beforeHandlerFunc]);

        ///////////////////////////
        // Отправляем данные формы.
        xhr.send(sent_data);

		////////////////////////////////////////
		// Завершаем работу функции-обработчика.
		return false;
	}
	else
	{
		/////////////////////////////////////////
		// Выводим сообщение в виде silent блока.
		$().toastmessage('showToast', { text: 'Запрошенная на отправку форма не обнаружена!', sticky: false, position: 'top-left', type: 'error' }); return false;
	}
}

////////////////////////////////////////////////////////////////////
// Функция отправки данных по форме с помощью плагина "jquery-form".
// На базе этой функции реализовано отображение прогресса загрузки файлов на сервер.
// Функция принимает следующие параметры:
//		event 					- событие вызвавшее эту функцию или значение false.
// 		formUID 				- идентификатор обрабатываемой формы
//		successHandlerFunc 		- функция запускаемая по успешному завершению передачи данных по форме
//		beforeHandlerFunc 		- функция запускаемая перед началом передачи данных по форме
//		afterHandlerFunc 		- функция запускаемая после окончания передачи данных по форме
//		beforeCallbackFunc 		- функция запускаемая перед началом обработки формы.
function extWinInfHandlerJqueryFormRequest(event, formUID, successHandlerFunc, beforeHandlerFunc, afterHandlerFunc, beforeCallbackFunc)
{
	event = event || window.event;

	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если не передан идентификатор формы, то возвращаем сообщение об ошибке и завершаем работу обработчика.
	if (typeof(formUID) == 'undefined' || formUID == '')
	{
		/////////////////////////////////////////
		// Выводим сообщение в виде silent блока.
		$().toastmessage('showToast', { text: 'Не передан идентификатор обрабатываемой формы!', sticky: false, position: 'top-left', type: 'error' }); return false;
	}	

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если передана функция запускаемая перед началом обработки формы, то производим ее запуск и в зависимости от возвращенного ею ответа
	// производим либо отмену обработки формы, либо продолжение обработки формы.
	if (typeof(beforeCallbackFunc) != 'undefined' && beforeCallbackFunc != '' && typeof(window[beforeCallbackFunc]) == 'function')
	{
		var callbackRes = window[beforeCallbackFunc]( formUID );
		if (callbackRes == false) { return false; }
	}

	///////////////////////////////////////
	// Проверяем наличие формы на странице.
	if ($('#'+formUID).length > 0)
	{
		//////////////////////////////////////////////
		// Определяем используемыефункции-обработчики.
		if (typeof(successHandlerFunc) == 'undefined' || successHandlerFunc == '') { var successHandlerFunc = 'ongDefaultRequestResultShow'; }
		if (typeof(beforeHandlerFunc) == 'undefined' || beforeHandlerFunc == '') { var beforeHandlerFunc = 'ongActivateAjaxIcon'; }
		if (typeof(afterHandlerFunc) == 'undefined' || afterHandlerFunc == '') { var afterHandlerFunc = 'ongDeactivateAjaxIcon'; }	

		/////////////////////////////////
		// Определяем блок прогресс-бара.
		var progress = $('#'+formUID+' .progress');
		var bar = $('#'+formUID+' .progressBar');
		var percent = $('#'+formUID+' .progressBarPercent');
	
		///////////////////////////////
		// Биндим форму загрузки файла.
		$('#'+formUID).ajaxSubmit(
		{
			url: $('#'+formUID).attr('action'),
			type: $('#'+formUID).attr('method'),
			beforeSend:function(xhr) 
			{
				var percentVal = '0%';
				progress.css('display', 'block');
				bar.width(percentVal)
				percent.html(percentVal);

				var args = Array.prototype.slice.call(arguments); 
				args.unshift('beforeSend', beforeHandlerFunc); 
				ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder.apply(null, args);
			}, 
			uploadProgress: function(event, position, total, percentComplete) 
			{
				var percentVal = percentComplete + '%';
				bar.width(percentVal)
				percent.html(percentVal);
			},
			dataType: "json",
			success: function(xhr)
			{
				var percentVal = '100%';
				bar.width(percentVal)
				percent.html(percentVal);
				
				var args = Array.prototype.slice.call(arguments); 
				args.unshift('success', successHandlerFunc); 
				ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder.apply(null, args);
			},
			error: showErrorRedirect,
			complete:function(xhr) 
			{
				progress.css('display', 'none');

				var args = Array.prototype.slice.call(arguments); 
				args.unshift('complete', afterHandlerFunc); 
				ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder.apply(null, args);
			}
		});

		////////////////////////////////////////
		// Завершаем работу функции-обработчика.
		return false;
	}
	else
	{
		/////////////////////////////////////////
		// Выводим сообщение в виде silent блока.
		$().toastmessage('showToast', { text: 'Запрошенная на отправку форма не обнаружена!', sticky: false, position: 'top-left', type: 'error' }); return false;
	}
}

///////////////////////////////////////////////////////
// Функция обработки успешной передачи данных по форме.
// Функция не запускается автоматически, а должна быть специально вызвана из скрипта обработчика формы на сервере.
// Принимает следующие параметры:
// 		formUID 		- идентификатор обрабатываемой формы
//		resultAction 	- идентификатор результата передачи данных по форме, по которому определяется список необходимых к выполнению действий.
function extWinInfHandlerFormResult(formUID, resultAction)
{
	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если не передан идентификатор формы, то возвращаем сообщение об ошибке и завершаем работу обработчика.
	if (typeof(formUID) == 'undefined' || formUID == '')
	{
		/////////////////////////////////////////
		// Выводим сообщение в виде silent блока.
		$().toastmessage('showToast', { text: 'Не передан идентификатор обрабатываемой формы!', sticky: false, position: 'top-left', type: 'error' }); return false;
	}	

	///////////////////////////////////////
	// Проверяем наличие формы на странице.
	if ($('#'+formUID).length > 0)
	{
		////////////////////////////////////////////////////////
		// Определяем строку со списком обрабатываемых действий.
		var formResultConfs = '',
			formResultConfsList = [];

		////////////////////////////////////////////////////////////////
		// Определяем идентификатор результата передачи данных по форме.
		if (typeof(successHandlerFunc) =='undefined' || successHandlerFunc == '') { var successHandlerFunc = 'ongDefaultRequestResultShow'; }

		///////////////////////////////////////////////////////////////
		// Определяем наличие параметра с конифгурационными действиями.
		if (typeof(resultAction) == 'string' && resultAction != '' && typeof($('#'+formUID).data('ong_'+resultAction)) != 'undefined' && $('#'+formUID).data('ong_'+resultAction) != '')
		{
			formResultConfs = $('#'+formUID).data('ong_'+resultAction);
		}

		/////////////////////////////////////////////////////////////////////////////////////////
		// Определяем наличие в настройках обрабатываемой формы параметров действия по умолчанию.
		if (typeof($('#'+formUID).data('ong_default_postprocessing')) != 'undefined' && $('#'+formUID).data('ong_default_postprocessing') != '')
		{
			formResultConfs = formResultConfs.length > 0 ? formResultConfs + ';' + $('#'+formUID).data('ong_default_postprocessing') : $('#'+formUID).data('ong_default_postprocessing');
		}

		///////////////////////////////////////////////////////
		// Определяем список необходимых к выполнению действий.
		formResultConfsList = formResultConfs.split(';');
		for (var iConf = 0; iConf < formResultConfsList.length; iConf++)
		{
			//////////////////////////////////////////////////////////////////////////////////////////////////////////
			// Проверяем наличие запрошенного пост-обработчика формы в рабочем объекте библиотеки по работе с формами.
			if (typeof(ongFormHandler.formResultHandlers[formResultConfsList[iConf]]) == 'function')
			{
				ongFormHandler.formResultHandlers[formResultConfsList[iConf]]( formUID );
			}
			else
			{
				$().toastmessage('showToast', { text: 'Ошибка пост-обработки формы по методу "'+formResultConfsList[iConf]+'"! Не обнаружен запрашиваемый обработчик.', sticky: false, position: 'top-left', type: 'warning' });
			}
		}
	}
	else
	{
		/////////////////////////////////////////
		// Выводим сообщение в виде silent блока.
		$().toastmessage('showToast', { text: 'Запрошенная на обработку форма не обнаружена!', sticky: false, position: 'top-left', type: 'error' }); return false;		
	}
}