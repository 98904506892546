//////////////////////////////////////////////////////////////////
//	ONG Simple Lib												//
//	Автор RnD SoftLab (Davydov Lev aka S@DLer), 2014 - 2018.	//
//	Дата последнего изменения: 03.03.2018 23:28					//
//	Система: SimpleONG system.									//
//	GlobalSystemVersion: 1.2.2									//
//////////////////////////////////////////////////////////////////
// Библиотека использует в работе плагины:
// 		jQuery не ниже версии 1.7.2.
//		jQuery UI не ниже версии 1.11.0.
//		CKeditor версии 4.3.
//
//		Автор: Lev Davydov aka SaDLer.
//////////////////////////////////////////

////////////////////////////////////////
// Определяем рабочий объект библиотеки.
var ongDefaultHandler = 
{
	ongCurrSiteLink: 		 					window.location.protocol+'//'+window.location.hostname+window.location.pathname,
	ongCurrLang:								'ru',
	ongLocalization:							{},	
	ongLocalizationInit:						false,	
	ongCkeditorInstances: 						{},
	ongCKEditorDefConfs: 						{},
	ongRequestControlQueue: 					[],
	ongPageElemsHeightCalculateFunc: 			'ongPageElemsHeightCalculate',
	ongDOMElemsHandlers: 						{},

	//////////////////////////////////////////////
	// Набор функций управления модальными окнами:
	ongModalWindowsManageFuncs:
	{




	},

	///////////////////////////////////////////
	// Набор функций управления AJAX-запросами:
	ongAjaxRequestsFuncs:
	{
		//////////////////////////////////////////////////
		// Функция определения метода-обработчика запроса:
		// Первыми двумя аргументами обязательно должны быть:
		// 		funcType 		- тип запрашиваемого метода.
		// 		funcAlias 		- алиас запрашиваемого метода.
		ongHandlerFinder: 			function()
		{
			var funcType, funcAlias;
			var args = Array.prototype.slice.call(arguments);
			funcType = args.shift();
			funcAlias = args.shift();

			//////////////////////////////////////////////////////////////////////////////
			// В зависимости от типа запрашиваемого метода производится обработка запроса.
			switch (funcType)
			{
				case 'success':

					/////////////////////////////////////////////////
					// Производим поиск и запуск обработчика запроса.
					if (typeof(ongDefaultHandler.ongAjaxRequestsFuncs[funcAlias]) == 'object')
					{
						/////////////////////////////////
						// Определяем рабочие переменные.
						var reqParams = args.shift();
						var currObj = ongDefaultHandler.ongAjaxRequestsFuncs[funcAlias];

						/////////////////////////////////////////////////////////
						// Проверяем валидность объекта-обработчика ajax-запроса.
						if ('reqMethods' in currObj && typeof(currObj.reqMethods) == 'object')
						{
							////////////////////////////////////////////////////////////////////////////////////////////////////
							// Проверяем, что была передана переменная для обработки запроса. Иначе выводим сообщение об ошибке.
							if (typeof(reqParams) == 'object' && !jQuery.isEmptyObject(reqParams))
							{
								///////////////////////////////////////////////////////////////////////////////////////////////////////
								// Если у обработчика зарегистрирован метод предварительной обработки данных, то производим его запуск.
								if ('reqPreExec' in currObj && typeof(currObj.reqPreExec) == 'function') { currObj.reqPreExec.apply(null, reqParams); }

								///////////////////////////////////////////////////////////////////////////////////////////////////
								// Производим проверку наличия в результатах Ajax-запроса имени обработчика полученных результатов.
								if ('type' in reqParams)
								{
									////////////////////////////////////////////////////////////////////////////////
									// Производим проверку наличия запрошенного обработчика результата ajax-запроса.
									// Если такой обработчик не обнаружен, то при наличии ссылки на другой обработчик запросов, производим его запуск. Иначе завершаем работу сообщением об ошибке.
									if (reqParams.type in currObj.reqMethods && typeof(currObj.reqMethods[reqParams.type]) == 'function')
									{
										///////////////////////////////////////////////////////
										// Производим запуск обнаруженного обработчика запроса.
										currObj.reqMethods[reqParams.type]( reqParams );

										////////////////////////////////////////////////////////////////////////////////////////////
										// Если у обработчика зарегистрирован метод пост-обработки данных, то производим его запуск.
										if ('reqPostExec' in currObj && typeof(currObj.reqPostExec) == 'function') { currObj.reqPostExec.apply(null, reqParams); }

										///////////////////////////
										// Завершаем работу метода.
										return true;
									}
									else if ('reqParentHandler' in currObj && currObj.reqParentHandler != '' && currObj.reqParentHandler in ongDefaultHandler.ongAjaxRequestsFuncs && typeof(ongDefaultHandler.ongAjaxRequestsFuncs[currObj.reqParentHandler]) == 'object')
									{
										ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder ( funcType, currObj.reqParentHandler, reqParams);
										return true;
									}
									else
									{
										showErrorRedirect('', '', 'Ответ от сервера по запросу "'+reqParams.type+'" не распознан!');
										console.log(reqParams);
										return false;
									}
								}
								else
								{
									showErrorRedirect('', '', 'Неверный ответ от сервера!');
									return false;
								}
							}
							else
							{
								showErrorRedirect('Ajax-запрос не вернул данные для обработки!');
								return false;
							}
						}
						else
						{
							showErrorRedirect('Указанный объект-обработчик результата AJAX-запроса поврежден или неправильно сконфигурирован!');
							return false;
						}
					}
					else if (typeof(window[funcAlias]) == 'function')
					{
						window[funcAlias].apply(null, args);
					}
					else
					{
						$().toastmessage('showToast', { text: 'Обработчик запроса "'+funcAlias+'" не обнаружен.', sticky: false, position: 'top-left', type: 'error'});
					}

				break;
				default:

					/////////////////////////////////////////////////
					// Производим поиск и запуск обработчика запроса.
					if (typeof(ongDefaultHandler.ongAjaxRequestsFuncs[funcAlias]) == 'function')
					{
						ongDefaultHandler.ongAjaxRequestsFuncs[funcAlias].apply(null, args);
					}
					else if (typeof(window[funcAlias]) == 'function')
					{
						window[funcAlias].apply(null, args);
					}
					else
					{
						$().toastmessage('showToast', { text: 'Обработчик запроса "'+funcAlias+'" не обнаружен.', sticky: false, position: 'top-left', type: 'error'});
					}

				break;
			}
		},

		/////////////////////////////////////////////
		// Стандартная функция активации ajax-иконки.
		ongActivateAjaxIcon: 				function() { $('#ajax_loader_icon').removeClass('hidden'); },
		
		///////////////////////////////////////////////
		// Стандартная функция деактивации ajax-иконки.
		ongDeactivateAjaxIcon: 				function() { $('#ajax_loader_icon').addClass('hidden'); },

		////////////////////////////////////////////////////////
		// Стандартный метод обработки результатов AJAX-запроса.
		ongDefaultRequestResultShow:
		{
			reqMethods:
			{
				//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
				// Предупреждение. Подсвечиваем в форме поля с некорректными данными и добавляем текст ошибки под соответствующим полем ввода.
				warning: 							function(reqParams)
				{
					/////////////////////////////////////////////////////////////
					// Если не передано значеие kcaptcha_id, то ничего не делаем.
					if (typeof(reqParams.kcaptchaId) !='undefined' && reqParams.kcaptchaId != '')
					{
						//////////////////////////////
						// Обновляем картинку kaptcha.
						ongDefaultHandler.ongServiceFuncs.ongKcaptchaUpdate(reqParams.kcaptchaId); 
					}

					//////////////////////////////////////////////////////
					// Обрабатываем в цикле каждый элемент предупреждения:
					$.each(reqParams.warn_data, function (field, message) 
					{
						/////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// В зависимости от эелемента, к которому добавляется предупреждение, добавляем span соответствующим классом.
						if (ongDefaultHandler.ongServiceFuncs.ongChecktStrInput(/^textarea/, field))
							$('#'+reqParams.form_id+' '+field).addClass('warn_color').before('<span class="warn_message_base">'+message+'</span>');
						else if (ongDefaultHandler.ongServiceFuncs.ongChecktStrInput(/^select/, field))
							$('#'+reqParams.form_id+' '+field).addClass('warn_color').after('<span class="warn_message_indent">'+message+'</span>');
						else if (ongDefaultHandler.ongServiceFuncs.ongChecktStrInput(/^input/, field))
							$('#'+reqParams.form_id+' '+field).addClass('warn_color').after('<span class="warn_message_indent">'+message+'</span>');
						else if (ongDefaultHandler.ongServiceFuncs.ongChecktStrInput(/^#workPanel/, field))
							$(field+' > a').addClass('warn_color').append('<span class="warn_message_tab_title">'+message+'</span>');					
						else if (ongDefaultHandler.ongServiceFuncs.ongChecktStrInput(/^#/, field))
							$(field).after('<span class="warn_message_base">'+message+'</span>');
					});

					/////////////////////////////////////
					// Выводим общее сообщение об ошибке.
					$('#'+reqParams.form_id+' .formWarnMessAnchor').before('<span class="warn_message_base">В переданных Вами данных есть неккоректно заполненные поля!</span>');
				},

				////////////////////////////////////////////////////////
				// Обновление Kcaptcha кода и вывод сообщения об ошибке.
				kcaptcha_refresh: function(reqParams)
				{
					/////////////////////////////////////////////////////////////////
					// Выводим сообщение об успешной авторизации в виде silent блока.
					if (typeof(reqParams.message) != 'undefined' && reqParams.message != '') 
					{
						$().toastmessage('showToast', { text: reqParams.message, sticky: false, position: 'top-left', type: reqParams.messageType ? reqParams.messageType : 'notice' });
					}
					
					/////////////////////////////////////////////////////////////
					// Если не передано значеие kcaptcha_id, то ничего не делаем.
					if (typeof(reqParams.kcaptchaId) != 'undefined' && reqParams.kcaptchaId != '') { ongDefaultHandler.ongServiceFuncs.ongKcaptchaUpdate(reqParams.kcaptchaId); }
				},

				//////////////////////////////////////////////////////////
				// Стандартный обработчик по запуску указанных js-функций.
				// Принимает на обработку следующие параметры:
				//	request_confs 		- объект со списком функций для запуска.
				//		func_name		- имя запускаемой функции.
				//		func_type		- тип запускаемой функции (может отсутствовать). Передается для точного определения области поиска исполняемой функции.
				//		func_params		- параметры передаваемые на исполнение функции (может отсутствовать).
				//		func_timeout	- задержка выполнения функции в милесекундах (может отсутствовать).
				multiply_result_handler: 			function(reqParams)
				{
					/////////////////////////////////
					// Определяем рабочие переменные:
					var func_params, func_timeout, func_type, func_execute;

					//////////////////////////////////////////////////////////////////////////////////////
					// Сервисная функция выполнения подзапросов для мультиобработчика результатов запроса. 
					// Производит выполнение запрошенной задачи с учетом указанной задержки выполнения.
					function ongMultiplyResultExecute(func_execute, func_params, func_timeout)
					{
						///////////////////////////////////////////////////////////////////////////////////////////////////////////
						// В зависимости от наличия данных для передачи запрашиваемой функции, производим обработку вызова функций.
						if (typeof(func_params) == 'object')
						{
							////////////////////////////////////////////
							// Определяем переменную массива параметров.
							var args = [];

							///////////////////////////////////////////////////////
							// Определяем список параметров передаваемых в функцию.
							$.each(func_params, function(paramIndex, paramValue) { args.push(paramValue); });

							///////////////////////////////////////////////////
							// Запускаем целевую функцию со списком параметров.
							setTimeout( function() { func_execute.apply(null, args); }, func_timeout); 
						}
						else
						{
							/////////////////////////////
							// Запускаем целевую функцию.
							setTimeout( function() { func_execute; }, func_timeout); 
						}
					}

					///////////////////////////////////////////////////////////////////////
					// Проверяем, что был передан объект со списком запросов на выполнение.
					if (typeof(reqParams.request_confs) == 'object')
					{
						$.each(reqParams.request_confs, function(index, value) 
						{
							if (typeof(value.func_name) == 'string' || typeof(value.func_name) == 'function')
							{
								/////////////////////////////////
								// Сбрасываем рабочие переменные.
								if (typeof(value.func_timeout) == 'number' && value.func_timeout > 0) { func_timeout = value.func_timeout; } else { func_timeout = 0; }
								if (typeof(value.func_params) == 'object') { func_params = value.func_params; } else { func_params = ''; }
								if (typeof(value.func_type) == 'string') { func_type = value.func_type } else { func_type = 'all'; }

								///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
								// Определяем по приоритетности существование переданной на обработку функции и производим запрос на ее выполнение.
								if (typeof(value.func_name) == 'function' && (func_type == 'all' || func_type == 'self_func'))
								{
									 func_execute = value.func_name;
								}
								else if (typeof(ongDefaultHandler.ongModalWindowsManageFuncs[value.func_name]) == 'function' && (func_type == 'all' || func_type == 'modal_func'))
								{
									 func_execute = ongDefaultHandler.ongModalWindowsManageFuncs[value.func_name];
								}
								else if (typeof(ongDefaultHandler.ongAjaxRequestsFuncs[value.func_name]) == 'function' && (func_type == 'all' || func_type == 'ajax_func'))
								{
									func_execute = ongDefaultHandler.ongAjaxRequestsFuncs[value.func_name];
								}
								else if (typeof(ongDefaultHandler.ongServiceFuncs[value.func_name]) == 'function' && (func_type == 'all' || func_type == 'service_func'))
								{
									func_execute = ongDefaultHandler.ongServiceFuncs[value.func_name];
								}
								else if (typeof(window[value.func_name]) == 'function' && (func_type == 'all' || func_type == 'external_func'))
								{
									func_execute = window[value.func_name];
								}
								else
								{
									$().toastmessage('showToast', { text: 'Указанная в качестве подзапроса функция не обнаружена.', sticky: false, position: 'top-left', type: 'error' });
									console.log(value.func_name);
									return true;
								}

								////////////////////////////////////////////////////////////////////////////////////////////////////
								// Производим подзапрос на выполнение запрошенной задачи с учетом указанного таймаута на выполнение.
								ongMultiplyResultExecute(func_execute, func_params, func_timeout);
							}
							else
							{
								$().toastmessage('showToast', { text: 'Не передано имя функции для осуществления подзапроса.', sticky: false, position: 'top-left', type: 'error' });
							}
						}); 
					}
					else
					{
						$().toastmessage('showToast', { text: 'Не передан объект со списком подзапросов к функциям.', sticky: false, position: 'top-left', type: 'error' });
					}
				},

				//////////////////////////////////////////////////////////////////////////////////////////
				// Отображение блока с формой через расширение библиотеки CMS системы по работе с формами.
				// Принимаются следующие параметры:
				// form_id				- уникальный идентификатор формы.
				// form_show_type		- тип отображения формы (простая или обрабатываемая форма). Возможные значения - 'noActions', 'stdHandler'. Значение по умолчанию - 'stdHandler'.
				// dialogHandler		- идентификатор функций управления диалоговыми окнами. По умолчанию имеет значение 'DialogWinBlockStandart'.
				// dialogUID			- идентификатор контейнера диалогового окна. По умолчанию имеет значение 'dialogWinBlock'.
				// dialogConfs 			- объект со списком параметров передаваемых функции формирования модального окна с формой. 
				// 						  Ниже приведен список необходимых параметров для обработчика модальных окон "DialogWinBlockStandart".
				//		'xContent' 			- содержимое отображаемой формы.
				//		'xTitle' 			- название окна.
				//		'xWidth' 			- ширина окна.
				//		'xHeight' 			- высота окна.
				//		'xClasses' 			- список классов, добавляемых к контейнеру диалогового окна.
				//
				//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
				// Следующий список параметров принимается в порядке совместимости со скриптами использующими старый подход в формировании модальных окон.
				// В случае если будет передан массив с параметрами dialogConfs, то указанные ниже параметры не будут приниматься в расчет. 
				// win_width			- ширина окна.
				// win_height			- высота окна.
				// title				- название окна.
				// form_data			- содержимое отображаемой формы.
				// dialogClasses		- список классов, добавляемых к контейнеру диалогового окна.
				show_form_block: 					function(reqParams)
				{
					////////////////////////////////////////////////////////////////////////////////////////////////////////////
					// Если передан тип отображаемой формы как "noActions", то не проверяем передан идентификатор формы или нет.
					if (typeof(reqParams.form_show_type) == undefined || reqParams.form_show_type != 'noActions')
					{
						///////////////////////////////////////////////////////////////////////////////////////////
						// Определяем был ли передан идентификатор формы. Если нет, то выводим сообщение об ошибке.
						if (typeof(reqParams.form_id) == undefined || reqParams.form_id == '')
						{
							/////////////////////////////////////////
							// Выводим сообщение в виде silent блока.
							$().toastmessage('showToast', { text: 'Не передан уникальный идентификатор формы!', sticky: false, position: 'top-left', type: 'error' });
							 
							 ////////////////////////////////
							 // Завершаем работу обработчика.
							 return false;
						}
					}
					
					////////////////////////////////
					// Определяем рабочие переменные
					var 	dialogHandlerCreate = 'createDialogWinBlockStandart',
							dialogHandlerOpen = 'openDialogWinBlockStandart',
							dialogHandlerClose = 'closeDialogWinBlockStandart',
							dialogWindowConfs = {},
							callbackFunc;

					////////////////////////////////////////////////////
					// Определяем функции управления диалоговыми окнами.
					if (typeof(reqParams.dialogHandler) == 'string' && reqParams.dialogHandler != '')
					{
						dialogHandlerCreate = 'create'+reqParams.dialogHandler,
						dialogHandlerOpen = 'open'+reqParams.dialogHandler;
						dialogHandlerClose = 'close'+reqParams.dialogHandler;
					}

					/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
					// Определяем был ли передан идентификатор контейнера диалогового окна. Если нет, то устанавливаем значение по умолчанию.
					if (typeof(reqParams.dialogUID) == 'undefined' || reqParams.dialogUID == '') { reqParams.dialogUID = 'dialogWinBlock'; }

					///////////////////////////////////////////////////////////////////////////////////////
					// Определяем callback-функцию, выполняемую по завершению формирования модального окна.
					callbackFunc = function() 
					{
						/////////////////////////////
						// Открываем диалоговое окно.
						window[dialogHandlerOpen](reqParams.dialogUID);

						//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// Если передан тип отображаемой формы как "noActions", то не производим передачу управления обработчику инициализацией формы.
						if (typeof(reqParams.form_show_type) == undefined || reqParams.form_show_type != 'noActions')
						{
							//////////////////////////////////////////////////////
							// Передаем управление обработчику инициализации форм.
							extWinInfHandlerFormInitialize(reqParams.form_id);
						}
					}
					
					////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
					// В зависимости от способа передачи конфигурационных параметров производим заполнение массива соответствующими данными.
					// Это условие добавлено для режима совместимости со старыми версиями запроса на формирование модального окна.
					if (typeof(reqParams.dialogConfs) == 'object')
					{
						$.each(reqParams.dialogConfs, function(index, value) { dialogWindowConfs[index] = value; }); 
					}
					else
					{
						if (typeof(reqParams.form_data) != 'undefined') { dialogWindowConfs['xContent'] = reqParams.form_data; }
						if (typeof(reqParams.title) != 'undefined') { dialogWindowConfs['xTitle'] = reqParams.title; }
						if (typeof(reqParams.win_width) != 'undefined') { dialogWindowConfs['xWidth'] = reqParams.win_width; }
						if (typeof(reqParams.win_height) != 'undefined') { dialogWindowConfs['xHeight'] = reqParams.win_height; }
						if (typeof(reqParams.dialogClasses) != 'undefined') { dialogWindowConfs['xClasses'] = reqParams.dialogClasses; }
					}

					/////////////////////////////////////////////////////////////////////////////////
					// Определяем дополнительные параметры конфигурации формируемого модального окна:
					dialogWindowConfs['xUID'] = reqParams.dialogUID;
					dialogWindowConfs['callback'] = callbackFunc;

					/////////////////////////////
					// Формируем диалоговое окно.
					if (!window[dialogHandlerCreate](dialogWindowConfs)) { return false; }
				},

				////////////////////////
				// DEPRECATED HANDLER //
				///////////////////////////////////////////////////////////
				// Отображение пользовательской формы через окно сообщений.
				// Принимаются следующие параметры:
				// win_width			- ширина окна
				// win_height			- высота окна
				// title				- название окна
				// form_id				- уникальный идентификатор формы
				// successHandler		- имя функции обработчика при успешном возвращении результата запроса, по умолчанию равно 'ongDefaultRequestResultShow'
				// handlerFunc			- имя функции обрабатывающей событие onSubmit формы.
				// beforeHandlerFunc	- имя функции, выполняемой перед отправкой данных из формы.
				// afterHandlerFunc		- имя функции, выполняемой после отправки данных из формы.
				// bind_type			- способ забиндивания события onSubmit для формы:
				//							- no-bind: не производится забиндивание отображенной формы.
				//							- def_sentPData и sentPData: биндят форму по кнопке с id sentPData+form_id
				//							- любые другие значения или их отсутствие: биндят форму по submit событию
				// uiTabsFlag			- флаг определяющий необходимость активации jquery-ui виджета tabs
				// uiTabsName			- идентификатор div блока из которого необходимо сделать табы.
				// uiToolTipsFlag		- флаг определяющий необходимость активации на форме виджета подсказок.
				// uiToolTipsName		- имя класса элементов по которым будет активирован виджет подсказок.
				// dialogHandler		- идентификатор функций управления диалоговыми окнами. По умолчанию имеет значение 'DialogWinBlockStandart'.
				// dialogUID			- идентификатор контейнера диалогового окна.
				// dialogClasses		- список классов, добавляемых к контейнеру диалогового окна.
				// ckeditFlag			- Флаг необходимости подключения редактора CKEditor
				// ckeditList			- Список элементов textarea на которые необходимо навесить редактор CKEditor.
				// ckeditConfigsList	- Конфигурационные массивы для подключаемых textarea блоков.
				show_default_form: 					function(reqParams)
				{
					/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
					// Определяем был ли передан идентификатор контейнера диалогового окна. Если нет, то устанавливаем значение по умолчанию.
					if (typeof(reqParams.dialogUID) == 'undefined' || reqParams.dialogUID == '') { reqParams.dialogUID = 'dialogWinBlock'; }
					
					////////////////////////////////////////////////////
					// Определяем функции управления диалоговыми окнами.
					if (typeof(reqParams.dialogHandler) == 'undefined' || reqParams.dialogHandler == '')
					{
						var dialogHandlerCreate = 'createDialogWinBlockStandart',
							dialogHandlerOpen = 'openDialogWinBlockStandart',
							dialogHandlerClose = 'closeDialogWinBlockStandart';
					}
					else
					{
						var dialogHandlerCreate = 'create'+reqParams.dialogHandler,
							dialogHandlerOpen = 'open'+reqParams.dialogHandler,
							dialogHandlerClose = 'close'+reqParams.dialogHandler;
					}

					//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
					// До формирования окна с контентом, предварительно удаляем ckeditor для textarea полей, если передан соответствующий список textarea блоков для активации визуального редактора CKEditor.
					if (reqParams.ckeditFlag == true)
					{
						//////////////////////////////////////////////////////////////
						// Запускаем функцию уничтожения инстансов редактора CKEditor.
						$.each(reqParams.ckeditList, function(i, field) { ongDefaultHandler.ongServiceFuncs.ongRemoveCKEditor(field); });
					}			

					///////////////////////////////////////////////////////////////////////////////////////
					// Определяем callback-функцию, выполняемую по завершению формирования модального окна.
					var callbackFunc = function() 
					{
						////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// Активируем ckeditor для textarea полей, если передан соответствующий список textarea блоков для активации визуального редактора CKEditor.
						if (reqParams.ckeditFlag == true)
						{
							$.each(reqParams.ckeditList, function(i, field)
							{
								/////////////////////////////////////////////////////////////////////////////////////////////////////////
								// Если установлена переменная с особой конфигурацией редактора, то используем ее при создании редактора.
								// Иначе используем стандартные настройки.
								if (typeof(reqParams.ckeditConfigsList) != 'undefined' && 
									reqParams.ckeditConfigsList != '' && 
									reqParams.ckeditConfigsList[field] != '' && 
									typeof(reqParams.ckeditConfigsList[field]) == 'string' && 
									reqParams.ckeditConfigsList[field] != null)
								{
									///////////////////////////////////////////////////////////////////////////////
									// Пытаемся преобразовать переданную строку с конфигурацией редактора в объект.
									// На случай если строка является невалидной, производим безопасный вызов функции парсинга.
									// Если преобразовать строку в объект не удалось, то устанавливаем конфигурационные параметры по умолчанию.
									try
									{
										////////////////////////////////////////////////////////////////////////////////////
										// Определяем конфигурационные параметры формируемого редактора для текстового поля.
										var editor_confs = JSON.parse(reqParams.ckeditConfigsList[field]); 
									}
									catch(e) 
									{  
										////////////////////////////////////////////////////////////////////////////////////
										// Определяем конфигурационные параметры формируемого редактора для текстового поля.
										var editor_confs = {};
									}
								}
								else
								{
									////////////////////////////////////////////////////////////////////////////////////
									// Определяем конфигурационные параметры формируемого редактора для текстового поля.
									var editor_confs = {};
								}
								
								///////////////////////////////////////
								// Создаем CKEditor на текстовом блоке.
								ongDefaultHandler.ongServiceFuncs.ongCreateCKEditor(field, editor_confs)					
							});
						}
						else
						{
							reqParams.ckeditList = [];
						}
						
						/////////////////////
						// Активируем UI-Tabs
						if (reqParams.uiTabsFlag == true && typeof(reqParams.uiTabsName) !='undefined' && reqParams.uiTabsName != '')
						{
							$(function() {
								$( "#"+reqParams.uiTabsName ).tabs();
							});
						}
						
						//////////////////////
						// Активируем tooltips
						if (reqParams.uiToolTipsFlag == true && typeof(reqParams.uiToolTipsName) !='undefined' && reqParams.uiToolTipsName != '')
						{
							$("."+reqParams.uiToolTipsName).tooltip({ 
							    bodyHandler: function() { 
							        return $($(this).attr("toolTipSrc")).html(); 
							    }, 
							    showURL: false 
							});
						}
						
						////////////////////////////////////////////////////
						// Определяем функцию-обработчик результата запроса.
						if (typeof(reqParams.successHandler) =='undefined' || reqParams.successHandler == '')
							{ var successHandlerFunc = 'ongDefaultRequestResultShow'; }
						else
							{ var successHandlerFunc = reqParams.successHandler; }
						
						/////////////////////////////////////////
						// Определяем функцию обработчик запроса.
						if (typeof(reqParams.handlerFunc) =='undefined' || reqParams.handlerFunc == '')
							{ var handlerFunc = 'defaultRequestHandler'; }
						else
							{ var handlerFunc = reqParams.handlerFunc; }
						
						///////////////////////////////////////////////////////////////////
						// Определяем функцию, выполняемую перед отправкой данных из формы.
						if (typeof(reqParams.beforeHandlerFunc) =='undefined' || reqParams.beforeHandlerFunc == '')
							{ var beforeHandlerFunc = 'ongActivateAjaxIcon'; }
						else
							{ var beforeHandlerFunc = reqParams.beforeHandlerFunc; }
						
						//////////////////////////////////////////////////////////////////
						// Определяем функцию, выполняемую после отправки данных из формы.
						if (typeof(reqParams.afterHandlerFunc) =='undefined' || reqParams.afterHandlerFunc == '')
							{ var afterHandlerFunc = 'ongDeactivateAjaxIcon'; }
						else
							{ var afterHandlerFunc = reqParams.afterHandlerFunc; }
						
						/////////////////////////////////////////////////////////////////
						// Определяем каким-образом биндить событие отправки данных формы
						// через стандартное submit событие или через нажатие специальной кнопки.
						// По умолчанию биндится стандартное submit событие.
						if (reqParams.bind_type == 'no_bind') { /* Не биндим никакие кнопки */ }
						else if (reqParams.bind_type == 'sentPData' || reqParams.bind_type == 'def_sentPData')
						{
							$("#sentPData"+reqParams.form_id).bind('click', function() 
							{
								window[handlerFunc]("form"+reqParams.form_id, successHandlerFunc, reqParams.ckeditFlag, reqParams.ckeditList, beforeHandlerFunc, afterHandlerFunc);
								return false;
							});			
						}
						else
						{
							$('#form'+reqParams.form_id).submit(function() 
							{
								window[handlerFunc]("form"+reqParams.form_id, successHandlerFunc, reqParams.ckeditFlag, reqParams.ckeditList, beforeHandlerFunc, afterHandlerFunc);
								return false;
							});
						}
						
						/////////////////////////////
						// Открываем диалоговое окно.
						window[dialogHandlerOpen](reqParams.dialogUID);
					}

					////////////////////////////////////////////////////////////////////
					// Определяем конфигурацию формируемого модального окна для запроса.
					var dialogWindowConfs = 
					{
						'xContent': 	reqParams.form_data, 
						'xTitle': 		reqParams.title, 
						'xWidth': 		reqParams.win_width, 
						'xHeight': 		reqParams.win_height, 
						'xUID': 		reqParams.dialogUID, 
						'xClasses': 	reqParams.dialogClasses,
						'callback': 	callbackFunc
					};
					
					/////////////////////////////
					// Формируем диалоговое окно.
					if (!window[dialogHandlerCreate](dialogWindowConfs)) { return false; }
				},
				
				/////////////////////////////////////
				// Блок вывода стандартных сообщений.
				message: 							function(reqParams)
				{
					////////////////////////////////////////////////////////////////////////////////////////
					// Если не передан UID диалогового окна, то используем стандартный UID "dialogWinBlock".
					if (typeof(reqParams.dialogUID) == 'undefined' || reqParams.dialogUID == '') { reqParams.dialogUID = 'dialogWinBlock'; }

					//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
					// Если не передан идентификатор класса диалогового окна, то используем стандартный класс "dialog_content_center".
					if (typeof(reqParams.dialogClass) == 'undefined' || reqParams.dialogClass == '') { reqParams.dialogClass = 'dialog_content_center'; }
					
					/////////////////////////////////////////
					// Формируем содержимое диалогового окна.
					var dialogContent = '<div class="dialogMessageBlock">'+reqParams.message+'</div>';

					///////////////////////////////////////////////////////////////////////////////////////
					// Определяем callback-функцию, выполняемую по завершению формирования модального окна.
					var callbackFunc = function() { openDialogWinBlockStandart(reqParams.dialogUID); }

					////////////////////////////////////////////////////////////////////
					// Определяем конфигурацию формируемого модального окна для запроса.
					var dialogWindowConfs = 
					{
						'xContent': 	dialogContent, 
						'xTitle': 		reqParams.title, 
						'xWidth': 		reqParams.win_width, 
						'xHeight': 		reqParams.win_height, 
						'xUID': 		reqParams.dialogUID, 
						'xClasses': 	[reqParams.dialogClass],
						'callback': 	callbackFunc
					};

					/////////////////////////////////////////
					// Формируем диалоговое окно с контентом.
					if (!createDialogWinBlockStandart(dialogWindowConfs)) { return false; }
				},
				
				///////////////////////////////////////////////
				// Блок вывода стандартных сообщений с кнопкой.
				message_button: 					function(reqParams)
				{
					////////////////////////////////////////////////////////////////////////////////////////
					// Если не передан UID диалогового окна, то используем стандартный UID "dialogWinBlock".
					if (typeof(reqParams.dialogUID) == 'undefined' || reqParams.dialogUID == '') { reqParams.dialogUID = 'dialogWinBlock'; }

					//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
					// Если не передан идентификатор класса диалогового окна, то используем стандартный класс "dialog_content_center".
					if (typeof(reqParams.dialogClass) == 'undefined' || reqParams.dialogClass == '') { reqParams.dialogClass = 'dialog_content_center'; }

					/////////////////////////////////////////////////////////////////////////
					// Если не передан тип сообщения, то используем по умолчанию тип 'error'.
					if (typeof(reqParams.messageType) == 'undefined' || reqParams.messageType == '') { reqParams.messageType = 'error'; }

					/////////////////////////////////////////
					// Формируем содержимое диалогового окна.
					var dialogContent = '<div class="dialogMessageBlock">'+reqParams.message+'</div><div class="dialogButtonsBlock"><input class="dialogButton" type="button" value="Закрыть" onclick="javascript:closeDialogWinBlockStandart(\''+reqParams.dialogUID+'\')" /></div>';

					///////////////////////////////////////////////////////////////////////////////////////
					// Определяем callback-функцию, выполняемую по завершению формирования модального окна.
					var callbackFunc = function() { openDialogWinBlockStandart(reqParams.dialogUID); }

					////////////////////////////////////////////////////////////////////
					// Определяем конфигурацию формируемого модального окна для запроса.
					var dialogWindowConfs = 
					{
						'xContent': 	dialogContent, 
						'xTitle': 		reqParams.title, 
						'xWidth': 		reqParams.win_width, 
						'xHeight': 		reqParams.win_height, 
						'xUID': 		reqParams.dialogUID, 
						'xClasses': 	[reqParams.dialogClass],
						'callback': 	callbackFunc
					};

					/////////////////////////////////////////
					// Формируем диалоговое окно с контентом.
					if (!createDialogWinBlockStandart(dialogWindowConfs)) { return false; }
				},
				
				//////////////////////////////////////////////////////////////////
				// Блок вывода стандартных сообщений с последующей переадресацией.
				message_ok_redirect: 				function(reqParams)
				{
					////////////////////////////////////////////////////////////////////////////////////////
					// Если не передан UID диалогового окна, то используем стандартный UID "dialogWinBlock".
					if (typeof(reqParams.dialogUID) == 'undefined' || reqParams.dialogUID == '') { reqParams.dialogUID = 'dialogWinBlock'; }

					//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
					// Если не передан идентификатор класса диалогового окна, то используем стандартный класс "dialog_content_center".
					if (typeof(reqParams.dialogClass) == 'undefined' || reqParams.dialogClass == '') { reqParams.dialogClass = 'dialog_content_center'; }

					/////////////////////////////////////////////////////////////////////////
					// Если не передан тип сообщения, то используем по умолчанию тип 'error'.
					if (typeof(reqParams.messageType) == 'undefined' || reqParams.messageType == '') { reqParams.messageType = 'error'; }

					/////////////////////////////////////////
					// Формируем содержимое диалогового окна.
					var dialogContent = '<div class="dialogMessageBlock">'+reqParams.message+'</div><div class="dialogButtonsBlock"><input class="dialogButton" type="button" value="Закрыть" onclick="javascript:ongDefaultHandler.ongServiceFuncs.ongRedirect(\''+reqParams.refresh_http+'\')" /></div>';

					///////////////////////////////////////////////////////////////////////////////////////
					// Определяем callback-функцию, выполняемую по завершению формирования модального окна.
					var callbackFunc = function() 
					{ 
						/////////////////////////////////////////////////////////
						// Добавляем событие на кнопку закрытия диалогового окна.
						$('#dialogWinBlock').bind('dialogclose', function() { ongDefaultHandler.ongServiceFuncs.ongRedirect(reqParams.refresh_http); });

						/////////////////////////////
						// Открываем диалоговое окно.
						openDialogWinBlockStandart(reqParams.dialogUID);
					}

					////////////////////////////////////////////////////////////////////
					// Определяем конфигурацию формируемого модального окна для запроса.
					var dialogWindowConfs = 
					{
						'xContent': 	dialogContent, 
						'xTitle': 		reqParams.title, 
						'xWidth': 		reqParams.win_width, 
						'xHeight': 		reqParams.win_height, 
						'xUID': 		reqParams.dialogUID, 
						'xClasses': 	[reqParams.dialogClass],
						'callback': 	callbackFunc
					};

					/////////////////////////////////////////
					// Формируем диалоговое окно с контентом.
					if (!createDialogWinBlockStandart(dialogWindowConfs)) { return false; }
				},

				//////////////////////////////////////
				// Переадресация по указанному адресу.
				redirect: 							function(reqParams)
				{
					if (typeof(reqParams.refresh_http) == 'undefined' || reqParams.refresh_http == '') { var refresh_http = ongDefaultHandler.ongCurrSiteLink; } else { var refresh_http = reqParams.refresh_http; }
					window.location.href = refresh_http;
					return false;
				},
				
				/////////////////////////////////////////////////////
				// Стандартное отображение сообщения в silent режиме.
				silent_message: 						function(reqParams)
				{
					////////////////////////////////////////////////////////////////////////////////////////
					// Если не передан UID диалогового окна, то используем стандартный UID "dialogWinBlock".
					if (typeof(reqParams.dialogUID) == 'undefined' || reqParams.dialogUID == '') { reqParams.dialogUID = 'dialogWinBlock'; }
					
					//////////////////////////////////
					// Определяем основные переменные.
					var messType = reqParams.messageType || 'error',
						messPos = reqParams.messPos || 'top-left',
						messSticky = reqParams.messSticky ? true : false,
						messContent = reqParams.message || 'Сообщение не передано!';
					
					////////////////////////////
					// Выводим silent-сщщбщение.
					$().toastmessage('showToast', { text: messContent, sticky: messSticky, position: messPos, type: messType });

					///////////////////////////////////////////////////////////////
					// Закрываем модальное окно, если так установлено в настройках.
					if (reqParams.modal_close == true) { closeDialogWinBlockStandart(reqParams.dialogUID); }
				},

				///////////////////////////////////////////////////////////////////////////////////////////////////
				// Стандартное отсутствие каких-либо действий. Необходимо, чтобы аякс-иконка не зависала на экране.
				no_action: 							function(reqParams)
				{ 
					/* Ничего не предпринимаем. */ 
				}
			},
			reqPreExec: function(reqParams)
			{
				////////////////////////////////////////////////////////////////////
				// Убираем комментирование возникших ошибок при передаче информации.
				$('.warn_message').remove();
				$('.warn_message_tab_title').remove();
				$('.warn_message_base').remove();
				$('.warn_message_indent').remove();
				$('*').removeClass('warn_color');
			},
			reqPostExec: function(reqParams)
			{
				/////////////////////////////////////////////////////////////
				// Добавляем вызов функции рассчета высоты элементов шаблона.
				ongDefaultHandler.ongServiceFuncs.ongPageElemsHeightCalculateManage.exec();
			}
		}
	},

	///////////////////////////
	// Набор сервисных функций:
	ongServiceFuncs: 
	{
		///////////////////////////////////////////////////
		// Функция проверки строк по регулярному выражению.
		ongChecktStrInput: 					function (re, str) { if (re.test(str)) { return true; } else { return false; } },

		/////////////////////
		// Функция редиректа.
		ongRedirect:						function (refresh_http)
		{
			if (typeof(refresh_http) == 'undefined' || refresh_http == '') { refresh_http = ongDefaultHandler.ongCurrSiteLink; }
			window.location.href = refresh_http;
		},

		/////////////////////////////////////////////////////////////////////////////
		// Функция обновления kcaptcha картинки и очистки поля ввода kcaptcha текста.
		ongKcaptchaUpdate: 					function (kcaptcha_id)
		{
			/////////////////////////////////////////////////////////////
			// Если не передано значеие kcaptcha_id, то ничего не делаем.
			if (typeof(kcaptcha_id) !='undefined' && kcaptcha_id != '')
			{
				kcaptcha_link = $('#'+kcaptcha_id).attr('src');
				$("#"+kcaptcha_id).attr("src", kcaptcha_link+"&"+$.random(1000));
				$("#"+kcaptcha_id+'_text').val('');
			}
		},

		//////////////////////////////////////////////
		// Добавление функции на отслеживание событий.	
		ongAddEventListener: 				function(elem, type, eventHandle) 
		{
		    if (elem == null || typeof(elem) == 'undefined') return;
		    if ( elem.addEventListener) 
			{
		        elem.addEventListener( type, eventHandle, false );
		    } 
			else if ( elem.attachEvent) 
			{
				elem['e'+type+eventHandle] = eventHandle;
				elem[type+eventHandle] = function() { elem['e'+type+eventHandle]( window.event ); };
		        elem.attachEvent( "on" + type, elem[type+eventHandle] );
		    } 
			else 
			{
		        elem["on"+type] = eventHandle;
		    }
		},

		///////////////////////////////////////////////////////
		// Добавление функции на удаление отслеживания событий.
		ongRemoveEventListener: 			function (elem, type, eventHandle) 
		{
		    if (elem == null || typeof(elem) == 'undefined') return;
		    if ( elem.removeEventListener) 
			{
		        elem.removeEventListener( type, eventHandle, false );
		    } 
			else if ( elem.detachEvent) 
			{
		        elem.detachEvent( 'on'+type, elem[type+eventHandle] );
		    	elem[type+eventHandle] = null;
		    } 
		},

		/////////////////////////////////////
		// Функция переадресации по таймауту.
		ongRequestTimeoutRedirect: 			function (secondsToClose, messageBlockID, redirectURL)
		{
			$('#'+messageBlockID).html('До автоматической переадресации осталось '+secondsToClose+' сек.');
			secondsToClose = secondsToClose - 1;
			
			if (secondsToClose > 0)
			{
				setTimeout(function() { ongDefaultHandler.ongServiceFuncs.ongRequestTimeoutRedirect(secondsToClose, messageBlockID, redirectURL); }, 1000);
			}
			else
			{
				window.location.href = redirectURL;
			}	
		},

		//////////////////////////////////////////////////////////////////////
		// Функция формирования правильной множественной формы русского слова.
		// ongDefaultHandler.ongServiceFuncs.ongPluralStr(num, ‘товар’,'товара’,'товаров’); // 12 товаров
		ongPluralStr: 						function (i, str1, str2, str3)
		{
			function plural (a)
			{
				if ( a % 10 == 1 && a % 100 != 11 ) return 0
				else if ( a % 10 >= 2 && a % 10 <= 4 && ( a % 100 < 10 || a % 100 >= 20)) return 1
				else return 2;
			}
			
			switch (plural(i)) 
			{
				case 0: return str1;
				case 1: return str2;
				default: return str3;
			}
		},

		///////////////////////////////////////////////////////////////////////////////////////
		// Функция проверяет, является ли переданная на проверку строка валидным json объектом.
		ongIsValidJSON: 					function (src) 
		{
		    var filtered = src;
		    filtered = filtered.replace(/\\["\\\/bfnrtu]/g, '@');
		    filtered = filtered.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']');
		    filtered = filtered.replace(/(?:^|:|,)(?:\s*\[)+/g, '');

		    return (/^[\],:{}\s]*$/.test(filtered));
		},

		///////////////////////////////////////////
		// Расширение: определение версии броузера.
		// Использование:
		//		ongBrowserDetect.browser == 'Explorer';
		//		ongBrowserDetect.version <= 9;
		ongBrowserDetect: 
		{
		    init: function () 
		    {
		        this.browser = this.searchString(this.dataBrowser) || "Other";
		        this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
		    },

		    searchString: function (data) 
		    {
		        for (var i=0 ; i < data.length ; i++)   
		        {
		            var dataString = data[i].string;
		            this.versionSearchString = data[i].subString;

		            if (dataString.indexOf(data[i].subString) != -1)
		            {
		                return data[i].identity;
		            }
		        }
		    },

		    searchVersion: function (dataString) 
		    {
		        var index = dataString.indexOf(this.versionSearchString);
		        if (index == -1) return;
		        return parseFloat(dataString.substring(index+this.versionSearchString.length+1));
		    },

		    dataBrowser: 
		    [
		        { string: navigator.userAgent, subString: "Chrome",  identity: "Chrome" },
		        { string: navigator.userAgent, subString: "MSIE",    identity: "Explorer" },
		        { string: navigator.userAgent, subString: "Firefox", identity: "Firefox" },
		        { string: navigator.userAgent, subString: "Safari",  identity: "Safari" },
		        { string: navigator.userAgent, subString: "Opera",   identity: "Opera" }
		    ]

		},

		///////////////////////////////////////////////////
		// Функция создания CKEditor'а для текстового поля.
		ongCreateCKEditor: 						function (editor_target, editor_confs)
		{
			/////////////////////////////////////////////////////////////////////////////////////////
			// Если не передан идентификатор элеменита DOM структуры, то выводим сообщение об ошибке.
			if (typeof(editor_target) != 'string' || editor_target == '')
			{
				///////////////////////////////
				// Выводим сообщение об ошибке.
				$().toastmessage('showToast', { text: 'По запросу на добавление CKEditor блока, не был передан идентификатор элемента для которого производится добавление.', sticky: false, position: 'top-left', type: 'error'});
				
				////////////////////////////////
				// Завершаем выполнение скрипта.
				return false;		
			}	
			
			//////////////////////////////////////////////////////////////////////////////////////////////
			// Если в DOM сруктуре отсутствует элемент для которого необходимо добавить редактор CKEditor,
			// то выводим сообщение об ошибке.
			if ($('#'+editor_target).length == 0)
			{
				///////////////////////////////
				// Выводим сообщение об ошибке.
				$().toastmessage('showToast', { text: 'На странице отсутствует запрошенный элемент для подключения редактора CKEditor.', sticky: false, position: 'top-left', type: 'error' });
				
				////////////////////////////////
				// Завершаем выполнение скрипта.
				return false;
			}
			
			//////////////////////////////////////////////////////////////
			// Если редактор уже создан, то предварительно уничтожаем его.
			// Это необходимо для того, чтобы формировать редактор с другими настройками.
			var CKEditor_handler = CKEDITOR.instances[editor_target];
			if (CKEditor_handler) { ongDefaultHandler.ongServiceFuncs.ongRemoveCKEditor(editor_target); }		

			//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
			// Определение конфигурации редактора. Если конфигурационные параметры переданы и не пусты, то используем их. Иначе формируем конфигурационные параметры по умолчанию.
			if (typeof(editor_confs) == 'object' && editor_confs != '' && editor_confs != null)
			{
				var config = editor_confs;
			}
			else if (typeof(ongDefaultHandler.ongCKEditorDefConfs) == 'object')
			{
				var config = ongDefaultHandler.ongCKEditorDefConfs;
			}
			else
			{
				var config = {};
			}
			
			//////////////////////
			// Создание редактора.
			// $('#'+editor_target).ckeditor( config );
			// Инициализируем редактор сообщений CKEditor с задержкой в 1 секунду после полной загрузки документа.
			// Этот хак необходим для нормальной работы редактора в WebKit броузерах.
			setTimeout ( function () { CKEDITOR.replace( editor_target, config); }, 1000 );
		},

		//////////////////////////////////////////////////////
		// Функция уничтожения CKEditor'а для текстового поля.
		ongRemoveCKEditor: 							function (editor_target)
		{
			/////////////////////////////////////////////////////////////////////////////////////////
			// Если не передан идентификатор элеменита DOM структуры, то выводим сообщение об ошибке.
			if (typeof(editor_target) == 'undefined' || editor_target == '')
			{
				///////////////////////////////
				// Выводим сообщение об ошибке.
				$().toastmessage('showToast', 
				{					
					text: 'По запросу на удаление CKEditor блока, не был передан идентификатор элемента для удаления.',
					sticky: false,
					position: 'top-left',
					type: 'error'
				});
				
				////////////////////////////////
				// Завершаем выполнение скрипта.
				return false;		
			}

			///////////////////////////////////////////////
			// Если редактор существует, то уничтожаем его.
			// На случай если в DOM структуре были произведены изменения повлекшие за собой нарушение 
			// целостности данных по инстансам, отлавливаем ошибки во время уничтожение редакторов.
			if (CKEDITOR.instances[editor_target]) { try{ CKEDITOR.instances[editor_target].destroy(true); delete CKEDITOR.instances[editor_target]; } catch(e) {}; }
		},

		/////////////////////////////////////////////////////////////////////////
		// Стандартная функция-обработчик запросов от блоков фильтрации контента.
		// Принимает следующие параметры:
		// 		(string)ongObject			- (обязательный) идентификатор контейнера с блоком фильрации.
		//		(string)ongHref				- (обязательный) шаблон ссылки переадресации.
		//		(bool)ongFilterClearFlag	- флаг необходимости очистки строки поиска перед формированием ссылки переадресации.
		ongFilterBlockHandlerDefault: 				function (ongObject, ongHref, ongFilterClearFlag)
		{
			////////////////////////////////////////////
			// Проверяем переданы ли необходимые данные.
			if (typeof(ongObject) == 'undefined' || typeof(ongHref) == 'undefined' || ongObject == '' || ongHref == '')
			{
				showError('Не переданы необходимые данные для формирования запроса!');
				return false;
			}

			/////////////////////////////////////////////////////////////////////////////////////
			// Определяем наличие на странице элементов запрашиваемого блока фильтрации контента.
			// Если по запрошенному блоку фильтрации не было обнаружено ни одного элемента, то возвращаем сообщение об ошибке.
			if ($('#'+ongObject).length == 0)
			{
				showError('Запрошенный блок фильтрации контента не обнаружен на странице!');
				return false;
			}
			
			/////////////////////////////////
			// Определяем рабочие переменные.
			var searchStr = '',	// Строка поиска
				searchType = ''; // Тип строки поиска
			
			////////////////////////////////////////////////////////
			// Обходим все элементы запрашиваемого блока фильтрации:
			$('#'+ongObject).find(':input').each(function(i, elem)
			{
				///////////////////////////////////////////////////////////////
				// Определяем наличие параметра с конифгурационными действиями.
				if (typeof($(elem).val()) != 'undefined' && typeof($(elem).data('ong_confs_filter')) != 'undefined' && $(elem).data('ong_confs_filter') != '')
				{
					////////////////////////////////////////////////////////////////////////////////////
					// Определяем список конфигурационных параметров элемента и производим их обработку.
					var elemConfsList = $(elem).data('ong_confs_filter').split(';');
					for (var iConf = 0; iConf < elemConfsList.length; iConf++)
					{
						switch(elemConfsList[iConf])
						{
							case 'searchType':
							
								///////////////////////////////////////////////////////////////////////
								// Определяем данные по типу поисковой строки в специальную переменную.
								searchType = '&' + $(elem).attr('name') + '=' + $(elem).val();
							
							break;
							case 'searchString':
							
								////////////////////////////////////////////////////////////////////
								// Если передан флаг очистки строки поиска, то обнуляем ее значение.
								if (typeof(ongFilterClearFlag) != 'undefined' && ongFilterClearFlag != '') { $(elem).val(''); }
								
								//////////////////////////////////////////////////////////////////////////////////
								// Если значение строки поиска не пустое, то добавляем его в строку переадресации.
								if ($(elem).val() != 'undefined' && $(elem).val() != '')
								{
									//////////////////////////////////////////////////////////////////
									// Определяем данные по поисковой строке в специальную переменную.
									searchStr = '&' + $(elem).attr('name') + '=' + $(elem).val();
								}
							
							break;
							case 'filterVal':
							
								ongHref += '&' + $(elem).attr('name') + '=' + $(elem).val();
							
							break;
						}
					}
				}
			});
			
			////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
			// По итогам проверки добавляем к формируемой строке переадресации переменные с типом поисковой строки и поисковую строку, если они были определены.
			if (searchStr != '') { ongHref += searchType + searchStr; }
			
			/////////////////////////////////////////////////////
			// Производим переадресацию по сформированной ссылке.
			window.location.href = ongHref;
			//console.log(ongHref);
		},

		////////////////////////////////////////////////////////////////////////////////////////////
		// Стандартная функция-обработчик запросов по отображению выбранной вкладки данных для формы.
		// Принимает следующие параметры:
		// 		(string)ongPanel		- (обязательный) идентификатор выбранной вкладки. Если передано значение '--default--', то активируется первая вкладка.
		// 		(string)ongUkey			- (необязательный) уникальный идентификатор обрабатываемой панели. Если не передан, то принимает значение 'default_work_data_panels'.
		ongSelectFormPanelHandlerDefault: 			function (ongPanel, ongUkey)
		{
			////////////////////////////////////////////
			// Проверяем переданы ли необходимые данные.
			if (typeof(ongPanel) =='undefined' || ongPanel == '')
			{
				showError('Не передан идентификатор вкладки данных для отображения!');
				return false;
			}

			/////////////////////////////////
			// Определяем рабочие переменные:
			if (typeof(ongUkey) == 'string' && ongUkey != '') 
			{ 
				var panelHeaderElemCommon = '.'+ongUkey+'_workPanelLink',
					panelHeaderElem = '#'+ongUkey+'_workPanel_',
					panelContentElemCommon = '.'+ongUkey+'_workPanelContentBlock',
					panelContentElem = '#'+ongUkey+'_workPanelCont_';
			}
			else
			{
				var panelHeaderElemCommon = '.defWorkPanelLink',
					panelHeaderElem = '#workPanel_',
					panelContentElemCommon = '.defaultWorkPanelContentBlock',
					panelContentElem = '#workPanelCont_';
			}

			/////////////////////////////////////////////////////////////////////////////////////////////////////////
			// Если параметр "ongPanel" равен значению '--default--', то ищем вкладку с data-параметром по умолчанию.
			if (ongPanel == '--default--' && $(panelHeaderElemCommon).length > 0)
			{
				panelHeaderElem += $(panelHeaderElemCommon).data('ong_panel_tab_uid');
				panelContentElem += $(panelHeaderElemCommon).data('ong_panel_tab_uid');
			}
			else
			{
				panelHeaderElem += ongPanel;
				panelContentElem += ongPanel;
			}

			///////////////////////////////////////////////////////////////////////
			// Скрываем все панели и сбрасываем текущий статус у заголовковпанелей.
			$(panelContentElemCommon).hide(0);
			$(panelHeaderElemCommon).removeClass('current_panel');
			
			///////////////////////////////////////////////////////////////////////////////////////////
			// Если существует запрошенная панель, то отображаем ее, иначе выводим сообщение об ошибке.
			if ($(panelContentElem).length > 0)
			{
				$(panelContentElem).show(0);
				
				/////////////////////////////////////////////////////////////////////////////////////////////
				// Если обнаружен соответсвующий заголовок панели, то применяем к нему класс "current_panel".
				if ($(panelHeaderElem).length > 0) { $(panelHeaderElem).addClass('current_panel'); }
			}
			else
			{
				/////////////////////////////////////////
				// Выводим сообщение в виде silent блока.
				$().toastmessage('showToast', { text: 'Запрошенная вкладка данных не обнаружена.', sticky: false, position: 'top-left', type: 'error' });					
			}

			//////////////////////////////////////////////////////////////////////////
			// Запускаем адгоритм определения минимальных размеров элементов страницы.
			ongDefaultHandler.ongServiceFuncs.ongPageElemsHeightCalculateManage.exec();
			
			////////////////////////////////
			// Завершаем работу обработчика.
			return false;
		},

		/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// Стандартная функция-обработчик запросов по скрытию дополнительных блоков формы и отображению запрошенного блока формы.
		// Данный функционал реализует скрытие ненужных элементов формы от глаз пользователя при выборе тех или иных параметров формы.
		// Функция принимает идентификатор селектора, определяет его значение и по полученым даным ищет соответствующий див-блок.
		// Принимает следующие параметры:
		// 		(string)ongSelectorID		- (обязательный) идентификатор селектора.
		ongSelectFormBlockHandlerDefault: 			function (ongSelectorID)
		{
			////////////////////////////////////////////
			// Проверяем переданы ли необходимые данные.
			if (typeof(ongSelectorID) =='undefined' || ongSelectorID == '')
			{
				/////////////////////////////////////////
				// Выводим сообщение в виде silent блока.
				$().toastmessage('showToast', 
				{
					text: 'Не передан идентификатор селектора!',
					sticky: false,
					position: 'top-left',
					type: 'error'
				});
				return false;
			}
			
			////////////////////////////
			// Скрываем все типы блоков.
			$('.selectorBlocks_'+ongSelectorID).hide();
			
			//////////////////////////////////////////////////
			// Проверяем существование запрошенного селектора.
			if ($('#'+ongSelectorID).length > 0)
			{
				//////////////////////////////////////////////////////////////////////////////////////////////////
				// Если для запрошенного на отображение блока существуют контейнеры, то производим их отображение.
				if ($('.selectorBlocks_'+ongSelectorID+'_'+$('#'+ongSelectorID).val()).length > 0)
				{
					$('.selectorBlocks_'+ongSelectorID+'_'+$('#'+ongSelectorID).val()).show();
				}
			}
			else
			{
				/////////////////////////////////////////
				// Выводим сообщение в виде silent блока.
				$().toastmessage('showToast', 
				{
					text: 'Запрошенный селектор не обнаружен на странице!',
					sticky: false,
					position: 'top-left',
					type: 'error'
				});
				return false;
			}
			
			////////////////////////////////
			// Завершаем работу обработчика.
			return false;
		},

		////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// Стандартная функция-обработчик запросов по включению/выключению списка чекбоксов относительно основного чекбокса.
		// Всем обрабатываемым чекбоксам блока должно быть установлено одинаковое имя класса. 
		// Это имя должно быть прописано у каждого checkbox-элемента в параметре "data-ong_checkboxes_list_class".
		// Для основного чекбокса должен быть дополнительно определен класс "ong_checkboxes_list_main_switch". 
		ongCheckboxesListSwitch: 					function (e)
		{
			/////////////////////////////////////
			// Проверяем наличие объекта события.
			if (typeof(e.target) != 'undefined')
			{
				var element = e.target;

				////////////////////////////////////////////////////////////////////
				// Определяем наличие данных по текущему контейнеру блока чекбоксов.
				if ($(element).data('ong_checkboxes_list_class') == 'undefined' || $('.'+$(element).data('ong_checkboxes_list_class')).length == 0) { return false; }

				//////////////////////////////////////////////
				// Определяем список обрабатываемых чекбоксов.
				var checkboxes = $('input[type=checkbox].'+$(element).data('ong_checkboxes_list_class'));

			    /////////////////////////////////////
			    // Если был изменен основной чекбокс:
			    if ($(element).hasClass('ong_checkboxes_list_main_switch')) 
			    {
					//////////////////////////////////////////////////////////
			        // Проходим весь список чекбоксов исключая первый элемент.
			        for (var i = 0, input; input = checkboxes[i++]; ) 
			        {
			        	////////////////////////////////////////////////////////////////////
			            // Устанавливаем каждому чекбоксу значение как у основного чекбокса.
			            if (!$(input).hasClass('ong_checkboxes_list_main_switch'))
			            {
			            	input.checked = element.checked;
			            }
			        }
				}

				////////////////////////////////////
			    // Если был изменен обычный чекбокс:
			    else 
			    {
			        var numChecked = 0,
			        	numMainCheckbox = 0;

			        for (var i = 1, input; input = checkboxes[i++]; ) 
			        {
			            if (input.checked) { numChecked++; }
			            if ($(input).hasClass('ong_checkboxes_list_main_switch')) { numMainCheckbox = i; }
			        }

			        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
			        // Если были выделены все чекбоксы, то выделяем основной чекбокс тоже, иначе снимаем выделение с основного чекбокса.
			        if ($(checkboxes[numMainCheckbox]).hasClass('ong_checkboxes_list_main_switch')) 
			        {
			        	checkboxes[numMainCheckbox].checked = numChecked === checkboxes.length - 1;
			        }
			   }
			}
		},

		/////////////////////////////////////////////////////////////////////////////////////////////
		// Функция управления определенным в настройках методом рассчета размеров элементов страницы.
		ongPageElemsHeightCalculateManage:
		{
			////////////////////////////////////////////////////////////////////////////////////////////////////////////
			// Метод содержит в себе назначенные на выполнение идентификаторы групп и дополнительные обработчики по ним. 
			execFuncs: {},
			initFlag: false,

			///////////////////////////////////////////////////////////////////////////
			// Метод инициализации функции выполнения расчетов размеров блоков шаблона.
			init: function()
			{
				var calcManage = ongDefaultHandler.ongServiceFuncs.ongPageElemsHeightCalculateManage;

				//////////////////////////////////////////
				// Определяем флаг успешной инициализации.
				calcManage.initFlag = true;

				/////////////////////////////////////////////////////////////////////////
				// Определяем исполняемый обработчик на изменение размеров окна браузера.
				ongDefaultHandler.ongServiceFuncs.ongAddEventListener(window, "resize", ongDefaultHandler.ongServiceFuncs.ongPageElemsHeightCalculateManage.exec);

				//////////////////////////////////////////////////////////////////////////////
				// Производим первичное выполнение обработчика расчета размеров блока шаблона.
				ongDefaultHandler.ongServiceFuncs.ongPageElemsHeightCalculateManage.exec();
			},

			/////////////////////////////////////////////////////////////////////////////////////////
			// Метод добавления на обработку новой группы или дополнительного обработчика для группы.
			add: function(type, scope, data)
			{
				var calcManage = ongDefaultHandler.ongServiceFuncs.ongPageElemsHeightCalculateManage;

				if (typeof(type) != 'string') { console.log('-- ong_err: type of heightCalcHandler is not set'); return false; }
				if (typeof(scope) != 'string') { console.log('-- ong_err: scope of heightCalcHandler is not set'); return false; }

				switch(type)
				{
					case 'scope':

						/////////////////////////////////////////////////////////////////////////////////////////////////
						// Определяем был ли переданы параметры автоактивации, автоинициализации и приоритета выполнения.
						// Параметры автоактивации и автоинициализации по умолчанию включены. Приоритет по умолчанию имеет значение 'normal'.
						var paramActive = true, paramInit = true, paramPriority = 'normal';
						if (data && typeof(data.active) != 'undefined' && data.active === false) { paramActive = false; }
						if (data && typeof(data.init) != 'undefined' && data.init === false) { paramInit = false; }
						if (data && typeof(data.priority) == 'string' && (data.priority == 'high' || data.priority == 'slow')) { paramPriority = data.priority; }

						///////////////////////////////////////////////////////////////////////////////////////////
						// Если запрошенная на добавление группа уже зарегистрирована, то завершаем работу функции.
						if (typeof(calcManage.execFuncs[scope]) != 'undefined') { console.log('-- ong_err: requested scope is already registred'); return false; }

						///////////////////////////////////////////////////////
						// Регистрируем новую группу на расчет высоты объектов.
						calcManage.execFuncs[scope] = { active: paramActive, priority: paramPriority, assignFunc: [], accessoryFunc: [] };

						/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// Если функция выполнения расчетов размеров блоков шаблона еще не инициализирована, то производим инициализацию.
						if (paramInit && !calcManage.initFlag)
						{
							calcManage.init();
						}

					break;

					case 'assignFunc':
					case 'accessoryFunc':

						if (typeof(calcManage.execFuncs[scope]) != 'object') { console.log('-- ong_err: requested scope is not registred'); return false; }
						if (typeof(data) != 'object') { console.log('-- ong_err: data of heightCalcHandler is not set'); return false; }

						/////////////////////////////////////////////////////////////////////////
						// Регистрируем для группы дополнительный обработчик расчета или функцию.
						if (typeof(calcManage.execFuncs[scope][type]) != 'object') { calcManage.execFuncs[scope][type] = []; }
						calcManage.execFuncs[scope][type].push(data);

					break;

					default:

						cosole.log('-- ong_err: type of heightCalcHandler is not recognized'); return false;

					break;
				}
			},

			/////////////////////////////////////////////////////////////////////////////////
			// Метод удаления из обработки группы или дополнительного обработчика для группы.
			remove: function(type, scope, funcNum)
			{
				var calcManage = ongDefaultHandler.ongServiceFuncs.ongPageElemsHeightCalculateManage;

				if (typeof(type) != 'string') { console.log('-- ong_err: type of heightCalcHandler is not set'); return false; }
				if (typeof(scope) != 'string') { console.log('-- ong_err: scope of heightCalcHandler is not set'); return false; }

				switch(type)
				{
					case 'scope':

						if (typeof(calcManage.execFuncs[scope]) == undefined) { console.log('-- ong_err: requested scope is not registred'); return false; }

						//////////////////////////////////////////
						// Удаляем группу расчета высоты объектов.
						delete calcManage.execFuncs[scope];

					break;

					case 'assignFunc':
					case 'accessoryFunc':

						if (typeof(funcNum) != 'number' || funcNum < 0) { console.log('-- ong_err: '+type+' num of heightCalcHandler is not set'); return false; }
						if (typeof(calcManage.execFuncs[scope]) != 'object') { console.log('-- ong_err: requested scope is not registred'); return false; }
						if (typeof(calcManage.execFuncs[scope][type][funcNum]) != 'undefined') { console.log('-- ong_err: requested '+type+' is not registred'); return false; }

						///////////////////////////////////////////////////////////////////////////////
						// Удаляем дополнительную функцию-обработчик из группы расчета высоты объектов.
						calcManage.execFuncs[scope][type].splice(funcNum, 1);

					break;

					default:

						cosole.log('-- ong_err: type of heightCalcHandler is not recognized'); return false;

					break;
				}
			},

			////////////////////////////////////////////////
			// Метод активации обработки запрошенной группы.
			activate: function(scope)
			{
				var calcManage = ongDefaultHandler.ongServiceFuncs.ongPageElemsHeightCalculateManage;

				if (typeof(scope) != 'string') { console.log('-- ong_err: scope of heightCalcHandler is not set'); return false; }
				if (typeof(calcManage.execFuncs[scope]) == undefined) { console.log('-- ong_err: requested scope is not registred'); return false; }

				///////////////////////////////////////////
				// Активируем обработку запрошенной группы:
				calcManage.execFuncs[scope].active = true;
			},

			//////////////////////////////////////////////////
			// Метод деактивации обработки запрошенной группы.
			deactivate: function(scope)
			{
				var calcManage = ongDefaultHandler.ongServiceFuncs.ongPageElemsHeightCalculateManage;

				if (typeof(scope) != 'string') { console.log('-- ong_err: scope of heightCalcHandler is not set'); return false; }
				if (typeof(calcManage.execFuncs[scope]) == undefined) { console.log('-- ong_err: requested scope is not registred'); return false; }

				/////////////////////////////////////////////
				// Деактивируем обработку запрошенной группы:
				calcManage.execFuncs[scope].active = false;
			},

			///////////////////////////////////////////////////////
			// Метод смены приоритета обработки запрошенной группы.
			setPriority: function(scope, priority)
			{
				var calcManage = ongDefaultHandler.ongServiceFuncs.ongPageElemsHeightCalculateManage;

				if (typeof(scope) != 'string') { console.log('-- ong_err: scope of heightCalcHandler is not set'); return false; }
				if (typeof(priority) !== 'string' || (priority != 'high' && priority != 'normal' && priority != 'slow')) { console.log('-- ong_err: priority of heightCalcHandler is not set'); return false; }
				if (typeof(calcManage.execFuncs[scope]) == undefined) { console.log('-- ong_err: requested scope is not registred'); return false; }

				/////////////////////////////////////////////
				// Деактивируем обработку запрошенной группы:
				calcManage.execFuncs[scope].priority = priority;
			},

			//////////////////////////////////////////////////////////////////////////////////
			// Метод запуска обработчика запрошенной группы или всех зарегистрированных групп.
			exec: function(event, scope)
			{
				var calcManage = ongDefaultHandler.ongServiceFuncs.ongPageElemsHeightCalculateManage;

				//////////////////////////////////////////////////////////////////////////////////////////////
				// Проверяем существование назначенного обработчика расчета размеров группы элементов шаблона.
				if (typeof(ongDefaultHandler.ongServiceFuncs[ongDefaultHandler.ongPageElemsHeightCalculateFunc]) == 'function')
				{
					var execHandler = ongDefaultHandler.ongServiceFuncs[ongDefaultHandler.ongPageElemsHeightCalculateFunc];
				}
				else if (typeof(window[ongDefaultHandler.ongPageElemsHeightCalculateFunc]) == 'function')
				{
					var execHandler = window[ongDefaultHandler.ongPageElemsHeightCalculateFunc];
				}
				else
				{
					cosole.log('-- ong_err: Height calculate function not found'); return false;
				}

				///////////////////////////////////////////////////////////////////////////////////
				// Если передан идентификатор группы и он зарегистрирован, то запускаем обработчик.
				// Если идентификатор группы не передан, то производим обход всех зарегистрированных активных групп. 
				if (scope)
				{
					if (typeof(scope) != 'string') { console.log('-- ong_err: '+scope+' of heightCalcHandler is not set', scope); return false; }
					if (typeof(calcManage.execFuncs[scope]) == undefined) { console.log('-- ong_err: requested '+scope+' is not registred', scope); return false; }
					if (typeof(calcManage.execFuncs[scope].active) == false) { console.log('-- ong_err: requested '+scope+' is not active', scope); return false; }

					////////////////////////////////////////////////////////////////////
					// Производим выполнение обработчика расчета для запрошенной группы.
					execHandler(scope, calcManage.execFuncs[scope].assignFunc, calcManage.execFuncs[scope].accessoryFunc);
				}
				else
				{
					/////////////////////////////////
					// Определяем очереди выполнения.
					var slowQueue = [], normalQueue = [], highQueue = [];

					//////////////////////////////////////////////////////////////////////////////
					// Проходим по всем зарегистрированным группам и составляем список выполнения.
					$.each(calcManage.execFuncs, function (currScope, currConf) 
					{
						if (currConf.active === true)
						{
							switch(currConf.priority)
							{
								case 'high': 	highQueue.push(currScope); break;
								case 'normal': 	normalQueue.push(currScope); break;
								case 'slow': 	slowQueue.push(currScope); break;
							}
						}
					});

					///////////////////////////////////////////////////////////////
					// Если определены, то обрабатываем группы высокого приоритета:
					if (highQueue.length > 0) 
					{
						for (var i = 0; i < highQueue.length; i++)
						{
							execHandler(highQueue[i], calcManage.execFuncs[highQueue[i]].assignFunc, calcManage.execFuncs[highQueue[i]].accessoryFunc);
						}
					}

					///////////////////////////////////////////////////////////////
					// Если определены, то обрабатываем группы обычного приоритета:
					if (normalQueue.length > 0) 
					{
						for (var i = 0; i < normalQueue.length; i++)
						{
							execHandler(normalQueue[i], calcManage.execFuncs[normalQueue[i]].assignFunc, calcManage.execFuncs[normalQueue[i]].accessoryFunc);
						}
					}

					//////////////////////////////////////////////////////////////
					// Если определены, то обрабатываем группы низкого приоритета:
					if (slowQueue.length > 0) 
					{
						for (var i = 0; i < highQueue.length; i++)
						{
							execHandler(slowQueue[i], calcManage.execFuncs[slowQueue[i]].assignFunc, calcManage.execFuncs[slowQueue[i]].accessoryFunc);
						}
					}
				}
			}
		},

		/////////////////////////////////////////////////////////////////////////////////////////////////////////////
		// Функция расчета размеров группы элементов шаблона и установки для них по необходимости минимальной высоты.
		// Данная функция используется по умолчанию, но ее можно переопределить в параметре: ongDefaultHandler.ongPageElemsHeightCalculateFunc
		// Использует следующие классы для поиска объектов шаблона при расчете высоты блоков:
		//		ong_tplMainBlock--[scope] 			- родительский блок всех рассчитываемых элементов.
		//		ong_tplConfBlock--[scope]			- блок конфигурационных data-параметров для текущей группы шаблона.
		//		ong_tplCalcBlock--[scope] 			- блок размер которого необходимо рассчитать.
		//		ong_tplHeaderBlock--[scope] 		- блок определяемый как шапка шаблона.
		//		ong_tplFooterBlock--[scope] 		- блок определяемый как подвал шаблона.
		ongPageElemsHeightCalculate: function (scope, assignedFuncs, accessoryFuncs)
		{
			////////////////////////////////////////////////////////////////////////////////////
			// Если не передан идентификатор обрабатываемой группы, то завершаем работу функции.
			if (typeof(scope) != 'string' || scope == '') { return false; }

			/////////////////////////////////
			// Определяем рабочие переменные.
			var docH = document.documentElement.clientHeight ? document.documentElement.clientHeight : window.innerHeight, // Размер окна.
				docHeaderHeight = 0, // Высота шапки шаблона
				docFooterHeight = 0, // Высота подвала шаблона
				docMainBlock = $('.ong_tplMainBlock--'+scope), // Ссылка на основной элемент шаблона.
				docConfBlock = $('.ong_tplConfBlock--'+scope), // Ссылка на конфигурационный элемент шаблона.				
				docCalcList = $('.ong_tplCalcBlock--'+scope), // Список элементов рассчета высоты.
				docHeaderList = $('.ong_tplHeaderBlock--'+scope), // Список элементов шапки.
				docFooterList = $('.ong_tplFooterBlock--'+scope), // Список элементов подвала.
				calcConfs = ''; // Строка конфигурационных настроек, если заданы.

			/////////////////////////////////////////////////////////////////////////////////////////////
			// Проверяем определена ли строка конфигурационных натроек для обрабатываемого блока шаблона.
			if ($(docConfBlock).length == 1 && typeof($(docConfBlock).data('ong_tpl_confs')) != undefined) { calcConfs = $(docConfBlock).data('ong_tpl_confs'); }
				
			///////////////////////////////////////////////////////////////////////////////////////////////////
			// Если не переданы объекты дополнительно назначенных обработчиков, то производим их инициализацию.
			if (typeof(assignedFuncs) != 'object') { assignedFuncs = []; }
			if (typeof(accessoryFuncs) != 'object') { accessoryFuncs = []; }

			///////////////////////////////////////////////////////////////////////
			// Производим выполнение закрепленных обработчиков до начала рассчетов.
			if (assignedFuncs.length > 0)
			{
				for (var aHandler = 0; aHandler < assignedFuncs.length; aHandler++)
				{
					if (typeof(assignedFuncs[aHandler].funcType) == 'string' && assignedFuncs[aHandler].funcType =='before' && typeof(assignedFuncs[aHandler].funcExec) == 'function')
					{
						assignedFuncs[aHandler].funcExec(scope);
					}
				}
			}

			/////////////////////////////////////
			// Определяем список элементов шапки.
			if ($(docHeaderList).length > 0)
			{
				for (var i = 0; i < $(docHeaderList).length; i++)
				{
					if ($(docHeaderList[i]).data('ong_tpl_calc') == 'calc')
					{
						docHeaderHeight = docHeaderHeight + $(docHeaderList[i]).outerHeight(true);
					}
				}
			}

			///////////////////////////////////////
			// Определяем список элементов подвала.
			if ($(docFooterList).length > 0)
			{
				for (var i = 0; i < $(docFooterList).length; i++)
				{
					if ($(docFooterList[i]).data('ong_tpl_calc') == 'calc')
					{
						docFooterHeight = docFooterHeight + $(docFooterList[i]).outerHeight(true);
					}
				}
			}

			////////////////////////////////////////////////////////////////////
			// Производим обход элементов, высоту которых необходимо рассчитать.
			if ($(docCalcList).length > 0)
			{
				docCalcListCycle:
				for (var i = 0; i < $(docCalcList).length; i++)
				{
					///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
					// Если по текущему элементу не определен параметр рассчета его высоты или он не отображается на странице, то пропускаем его обработку.
					if ($(docCalcList[i]).data('ong_tpl_calc') == 'calc' && $(docCalcList[i]).is(":visible"))
					{
						/////////////////////////////////
						// Сбрасываем рабочие переменные:
						var calcShift = 0,
							executeCalc = [];

						///////////////////////////////////////////////////////////////////////////////////////////////////////
						// Взависимости от утановленного режима рассчета высоты объекта выполняется соответствующий обработчик.
						switch($(docCalcList[i]).data('ong_tpl_calc_type'))
						{
							///////////////////////////////////////////////////////////////////////////////////////////////////
							// Режим обработки в котором размер высоты элемента рассчитывается с учетом высоты шапки и подвала.
							case 'inside': 

								calcShift = docHeaderHeight + docFooterHeight;

							break;

							///////////////////////////////////////////////////////////////////////////////////////////////////////////////
							// Режим обработки в котором размер высоты элемента рассчитывается с учетом высоты шапки, но без учета подвала.
							case 'under':

								calcShift = docHeaderHeight;

							break;

							///////////////////////////////////////////////////////////////////////////////////////////////////
							// Режим обработки в котором размер высоты элемента рассчитывается без учета высоты шапки и подвала.
							case 'full':

								calcShift = 0;

							break;

							default:

								console.log('-- ong_err: heightCalcHandler type not recognized');
								continue docCalcListCycle;

							break;
						}

						//////////////////////////////////////////////////////////////////////////////////////////
						// Определяем список дополнительных функций закрепленных за текущим обрабатываемым блоком.
						if (accessoryFuncs.length > 0)
						{
							for (var afunc = 0; afunc < accessoryFuncs.length; afunc++)
							{
								if (typeof(accessoryFuncs[afunc].funcTarget) == 'object' && typeof(accessoryFuncs[afunc].funcExec) == 'function')
								{
									//////////////////////////////////////////////////////////////////////////////////////////////////////////
									// Проверяем принадлежит ли текущая дополнительная функция-обработчик текущему обрабатываемому контейнеру.
									var checkParent = $(docCalcList[i]);
									if (checkParent.is(accessoryFuncs[afunc].funcTarget))
									{
										executeCalc.push(accessoryFuncs[afunc].funcExec);
									}
								}
							}
						}

						/////////////////////////////////////////////////////////////////////////////////////////////////////
						// Определяем значение, которое подвергается переопределению. По умолчанию это параметр "min-height".
						if (typeof($(docCalcList[i]).data('ong_tpl_calc_param')) != 'undefined' && $(docCalcList[i]).data('ong_tpl_calc_param') == 'height')
						{
							var calcParam = 'height', calcParamVal = 'auto';
						}
						else
						{
							var calcParam = 'min-height', calcParamVal = 0;
						}

						//////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// Если высота экрана меньше рассчитываемого блока, то производим сброс высоты блока до минимального значения
						// для выявления ситуации, когда блок имет такой размер не из-за контента, а из-за назначенной минимальной высоты.
						if (docH <= calcShift + $(docCalcList[i]).outerHeight(true)) { $(docCalcList[i]).css(calcParam, calcParamVal); }

						//////////////////////////////////////
						// Определяем переменные для рассчета:
						var setDocH 	= docH - calcShift,							// Рассчетная высота блока для установки.
							currBlockH 	= $(docCalcList[i]).outerHeight(true),		// Текущая высота рассчитываемого блока.
							calcConfirm	= false;									// Флаг необходимости определения рассчетной высты к блоку.
							calcMods 	= $(docCalcList[i]).data('ong_tpl_calc_mods') ? $(docCalcList[i]).data('ong_tpl_calc_mods') : null;


						//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// Если текущая высота экрана больше суммы размера блока и рассчтеного отступа, то определяем флаг необходимости переопределения высоты блока.
						if (docH > calcShift + $(docCalcList[i]).outerHeight(true)) { calcConfirm = true; }

						///////////////////////////////////////////////////////////////////////////////////////////////////
						// Определяем наличия дополнительного модификатора рассчета блока на весь экран при любых условиях.
						if (typeof(calcMods) == 'string' && calcMods.indexOf('fullscreen') !== -1) { calcConfirm = true; }

						//////////////////////////////////////////////////////////////////////////////////
						// Определяем наличия дополнительного модификатора учета минимальной высоты блока.
						if (typeof(calcMods) == 'string' && calcMods.indexOf('min_height') !== -1 && calcParam != 'min-height')
						{ 
							if ($(docCalcList[i]).css('min-height') && $(docCalcList[i]).css('min-height') > 0 && setDocH < $(docCalcList[i]).css('min-height') && calcConfirm)
							{
								setDocH = parseInt($(docCalcList[i]).css('min-height'));
							}
							else if ($(docCalcList[i]).css('min-height') && $(docCalcList[i]).css('min-height') > 0 && currBlockH < $(docCalcList[i]).css('min-height') && !calcConfirm)
							{
								calcConfirm = true;
								setDocH = parseInt($(docCalcList[i]).css('min-height')); 
							}
						}

						///////////////////////////////////////////////////////////////////////////////////
						// Определяем наличия дополнительного модификатора учета максимальной высоты блока.
						if (typeof(calcMods) == 'string' && calcMods.indexOf('max_height') !== -1) 
						{
							if ($(docCalcList[i]).css('max-height') && $(docCalcList[i]).css('max-height') > 0 && setDocH > $(docCalcList[i]).css('max-height') && calcConfirm)
							{
								setDocH = parseInt($(docCalcList[i]).css('max-height'));
							}
							else if ($(docCalcList[i]).css('max-height') && $(docCalcList[i]).css('max-height') > 0 && currBlockH > $(docCalcList[i]).css('max-height') && !calcConfirm)
							{
								calcConfirm = true;
								setDocH = parseInt($(docCalcList[i]).css('max-height')); 
							}
						}

						///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
						// Если флаг необходимости определения рассчетной высты к блоку установлен в true, то применяем полученный рассчет к блоку.
						if (calcConfirm)
						{
							////////////////////////////////////////////////////////////////////////////////////////////
							// Производим запуск на исполнение назначенных функций дополнительного расчета высоты блока.
							for (var afunc = 0; afunc < executeCalc.length; afunc++) { setDocH = executeCalc[afunc]( docCalcList[i], calcShift, setDocH ); }

							//////////////////////////////////////////
							// Устанавливаем минимальную высоту блока.
							$(docCalcList[i]).css(calcParam, setDocH);
						}
					}
				}
			}

			//////////////////////////////////////////////////////////////////////////////
			// Производим выполнение закрепленных обработчиков после завершения рассчетов.
			if (assignedFuncs.length > 0)
			{			
				for (var aHandler = 0; aHandler < assignedFuncs.length; aHandler++)
				{
					if (typeof(assignedFuncs[aHandler].funcType) == 'string' && assignedFuncs[aHandler].funcType =='after' && typeof(assignedFuncs[aHandler].funcExec) == 'function')
					{
						assignedFuncs[aHandler].funcExec(scope);
					}
				}
			}
		},

		////////////////////////////////////////////////////////////////////
		// Добавляем сервисную функцию инициализации выпадающего меню-блока.
		// Функция принимает следующие параметры:
		//		- класс кнопки при нажатии на которую осуществляется выпадание/свертывание меню-блока.
		//		- класс контейнера с меню-блоком.
		//
		// Для работы функции необходимо подключить библиотеку обработки стандартны событий.
		ongDefaultDropdownMenuInit: function( dropDownButton, dropDownContainer )
		{
			/////////////////////////////////////////////////////////////////////////////////////////////////////////
			// Добавляем отслеживание кликов по кнопке открытия/скрытия выпадающей панели выбора блоков конфигурации.
			ongEventHandler.ongCallbackSet(
				'click',
				{ 
					'elemTarget': '.'+dropDownButton, 
					'clickPos': 'inside', 
					'funcCallback': function(e, dropDownContainer)
					{
						/////////////////////////////////////////////////////////////////
						// Проверяем, что отслеживаемый элемент присутствует на странице.
						if (typeof(dropDownContainer) == 'string' && $('.'+dropDownContainer).length > 0) 
						{
							///////////////////////////////////////////////////////////////////////////////////////////////////
							// Если по обрабатываемому блоку в момент клика производится анимация, то завершаем работу функции.
							if ($('.'+dropDownContainer).hasClass('currBlockAnimate')) { return true; }

							///////////////////////////////////
							// Добавляем класс начала анимации:
							$('.'+dropDownContainer).addClass('currBlockAnimate');

							////////////////////////////////////////////////////////////////////////////////
							// Если обрабатываемый блок отображается, то производим его закрытие и наоборот.
							if ($('.'+dropDownContainer).is(":visible"))
							{
								$('.'+dropDownContainer).fadeOut(300, function() { $('.'+dropDownContainer).removeClass('currBlockAnimate'); });
							}
							else
							{
								$('.'+dropDownContainer).fadeIn(300, function() { $('.'+dropDownContainer).removeClass('currBlockAnimate'); });
							}
						}
					}, 
					'funcParams': [ dropDownContainer ] 
				}
			);

			/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
			// Добавляем отслеживание кликов вне панели выбора блоков конфигурации для скрытия этой панели если она отображается.
			ongEventHandler.ongCallbackSet(
				'click',
				{ 
					'elemTarget': '.'+dropDownContainer, 
					'clickPos': 'outside', 
					'funcCallback': function(e, dropDownContainer)
					{
						/////////////////////////////////////////////////////////////////
						// Проверяем, что отслеживаемый элемент присутствует на странице.
						if (typeof(dropDownContainer) == 'string' && $('.'+dropDownContainer).length > 0) 
						{
							///////////////////////////////////////////////////////////////////////////////////////////////////
							// Если по обрабатываемому блоку в момент клика производится анимация, то завершаем работу функции.
							if ($('.'+dropDownContainer).hasClass('currBlockAnimate')) { return true; }

							///////////////////////////////////
							// Добавляем класс начала анимации:
							$('.'+dropDownContainer).addClass('currBlockAnimate');

							////////////////////////////////////////////////////////////////////////////////
							// Если обрабатываемый блок отображается, то производим его закрытие и наоборот.
							if ($('.'+dropDownContainer).is(":visible"))
							{
								$('.'+dropDownContainer).fadeOut(300, function() { $('.'+dropDownContainer).removeClass('currBlockAnimate'); });
							}
						}
					}, 
					'funcParams': [ dropDownContainer ] 
				}
			);
		},

		//////////////////////////////////////////////////////////
		// Extract "GET" parameters from a JS include querystring.
		// Thanks to James Smith, British entrepreneur and developer based in San Francisco.
		// Возвращает объект из ссылки на текущий скрипт и при наличии списка переданных параметров.
		ongScriptGetParams: function getParams( script_name )
		{
			/////////////////////////////////
			// Находим все теги со скриптами.
			var scripts = document.getElementsByTagName("script");

			/////////////////////////////////////////////////////////
			// Пытаемся обнаружить запрошенный скрипт по имени файла.
			for(var i=0; i<scripts.length; i++) 
			{
				if(scripts[i].src.indexOf("/" + script_name) > -1) 
				{
					/////////////////////////////////////////////////////////////
					// Получаем текущий путь к файлу скрипта и список параметров.
					var src_tpl = scripts[i].src.split("?");
					var src_path = src_tpl.shift();
					var src_search = src_tpl.length > 0 ? src_tpl.pop().split("&") : [];

					/////////////////////////////////////////////////////////////////////////////////////////////////
					// Разделяем каждый параметр имеющий вид "key=value" в массив и пересохраняем результат в объект.
					var p = {};
					for(var j=0; j<src_search.length; j++) 
					{
						var kv = src_search[j].split("=");
						p[kv[0]] = kv[1];
					}

					///////////////////////////////////////
					// Возвращаем сформированный результат.
					return { script: src_path, params: p };
				}
			}
		  
		  ///////////////////////
		  // Скрипт не обнаружен.
		  return false;
		},

		///////////////////////////////////////////////////////////////////
		// Метод по подключению скрипта локализации для текущей библиотеки.
		ongInitCurrentScriptLocalization: function()
		{
			//////////////////////////////////////////////////////////////////////
			// Если текущий метод уже выполнялся на странице, то завершаем работу.
			if (ongDefaultHandler.ongLocalizationInit) { console.log('ONG Base lib localization already init.'); return false; }

			///////////////////////////////////////////////////////////////////////////////
			// Определяем наличие переданных в адресной строке конфигурационных параметров.
			var currParams = ongDefaultHandler.ongServiceFuncs.ongScriptGetParams('defaultInfWinHandler-1.2');

			//////////////////////////////////////////////////////////////////////////////////////////////////
			// Если загрузить данные по текущему скрипту не удалось, то выводим в консоль сообщение об ошибке.
			if (!currParams || typeof(currParams.script) == 'undefined' || currParams.script == '') { console.log('ONG Error loading base lib script params.'); return false; }

			///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
			// Если в переданных параметрах присутствует параметр "lang" и он состоит изу двух букв, то заменяем текущий используемый язык.
			if (typeof(currParams.params) !== 'undefined' && typeof(currParams.params.lang) !== 'undefined' && currParams.params.lang.search(/[a-z]{2}/i) != -1) { ongDefaultHandler.ongCurrLang = currParams.params.lang; }

			/////////////////////////////////////////////////////////
			// Определяем путь до подключаемого файла текущей локали.
			var currLangIncludePath = currParams.script+'/_lang/'+ongDefaultHandler.ongCurrLang+'.js';





			//console.log(currLangIncludePath, ongDefaultHandler.ongCurrLang, currParams.script, currParams);


			/////////////////////////////////////////////////////////
			// Определяем флаг успешной инициализации текущей локали.
			ongDefaultHandler.ongLocalizationInit = true;
		},

		///////////////////////////////////////////
		// МЕТОД ОПРЕДЕЛЕНИЯ МОБИЛЬНОГО УСТРОЙСТВА.
		ongCheckIsMobile: function(checkStr)
		{
			////////////////////////////////////////////////////
			// Проверка на то, является ли устрйоство мобильным.
			var isMobile = 
			{
			    Android: function() 
			    {
			        return navigator.userAgent.match(/Android/i);
			    },
			    BlackBerry: function() 
			    {
			        return navigator.userAgent.match(/BlackBerry/i);
			    },
			    iOS: function() 
			    {
			        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
			    },
			    Opera: function() 
			    {
			        return navigator.userAgent.match(/Opera Mini/i);
			    },
			    Windows: function() 
			    {
			        return navigator.userAgent.match(/IEMobile/i);
			    },
			    Orientation: function()
			    {
					return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
			    },
			    any: function() 
			    {
			        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
			    }
			};

			//////////////////////////////////////////////////////////////////////////////////
			// Если не передан запрашиваемый тип проверки, то определяем его в значение 'any'.
			if (typeof(checkStr) == 'undefined' || typeof(isMobile[checkStr]) != 'function') { checkStr = 'any'; }

			///////////////////////
			// Производим проверку.
			return isMobile[checkStr]();
		}
	},

	/////////////////////////////////////////////////////////////
	// Определяем флаг успешной нициализации основной библиотеки.
	ongHandlerInitFlag_default: true
};

///////////////////////////////////////////////////////////
// ПРОИЗВОДИМ ПОДГРУЗКУ ФАЙЛА ТЕКУЩЕЙ ЯЗЫКОВОЙ ЛОКАЛИЗАЦИИ.
ongDefaultHandler.ongServiceFuncs.ongInitCurrentScriptLocalization();

/////////////////////////
// DEPRECATED FUNCTION //
////////////////////////////////////////////////////////////////////////////////////////////////
// Функция для input блоков форм. Производит инициализацию эффекта текста в пустых input блоках.
function inputLabelInit(inputID)
{
	var C = $('label[for='+inputID+']')[0];
	if($('#'+inputID).val().length > 0) { C.style['top']='-9999px'; }
	//if($('#'+inputID).attr('value')) { C.style['top']='-9999px'; }
	//console.log(C, $('#'+inputID).val().length);
}

/////////////////////////
// DEPRECATED FUNCTION //
//////////////////////////////////////////////////////////////////////////////////
// Функция для input блоков форм. добавляет/убирает текст тега label в input блок.
// Если input блок пуст, то в него подставляется текст из тега label.
// Если в input блоке есть текст, то текст из тега label скрывается.
function inputLabelClean(A)
{
	var C=$('label[for='+A.id+']')[0];
	if(C)
	{
		if(!C.patched)
		{
			$(A).blur(function(){if(!this.value){C.style.top=''}})
		}
		C.patched=true;
		C.style['top']='-9999px';
	}
}

//////////////////////////////
// Основной блок управления //
//////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////
// DEPRECATED STATUS (Функция устарела и в будущих версиях библиотеки будет удалена).
// Функция обработчик форм: отправляет данные из формы по указанному в форме, в параметре 'action', адресу.
// Результирующие данные отправляются в функцию обработчик полученных результатов.
// Функция требует наличия переданного ей идентификатора формы.
function defaultRequestHandler(id, successHandlerFunc, ckeditFlag, ckeditList, beforeHandlerFunc, afterHandlerFunc)
{
	///////////////////////////////////////////////////////////////
	// Если не передано значеие id, то выводим сообщение об ошибке.
	if (typeof(id) =='undefined' || id == '')
	{
		showError('Не переданы идентификатор обрабатываемой формы!');
		return false;
	}
	else
	{
		/////////////////////////////////////////
		// Определяем функцию-обработчик запроса.
		if (typeof(successHandlerFunc) =='undefined' || successHandlerFunc == '') { var successHandlerFunc = 'ongDefaultRequestResultShow'; }

		// Определяем функцию выполняемую перед отправкой данных из формы.
		if (typeof(beforeHandlerFunc) =='undefined' || beforeHandlerFunc == '') { var beforeHandlerFunc = 'ongActivateAjaxIcon'; }
		
		// Определяем функцию, выполняемую после отправки данных из формы.
		if (typeof(afterHandlerFunc) =='undefined' || afterHandlerFunc == '') { var afterHandlerFunc = 'ongDeactivateAjaxIcon'; }

		////////////////////////////
		// Сериализуем данные формы.
		var form_serialized = $('#'+id).serializeArray();
		var sent_data = {};
		$.each(form_serialized, function (i, field) {
		   sent_data[field.name] = encodeURIComponent(field.value);
		});
		sent_data['curr_form_id'] = encodeURIComponent(id);
		sent_data['isAjaxRequest'] = 1;
		
		///////////////////////////////////////////////////////////////////////
		// Определяем, что передан параметр ckeditFlag и он имеет значение true
		// В этом случае пытаемся получить данные из окон визуального редактора CKEditor.
		if (typeof(ckeditFlag) != 'undefined' && ckeditFlag != '' && ckeditFlag == true )
		{
			if (ckeditList != 'undefined' && ckeditList != '')
			{
				$.each(ckeditList, function (i, field) 
				{
					/////////////////////////////////////////////////////////////////////
					// Определяем текущий инстанс редактора и запрашиваем его содержимое.
					var editor = CKEDITOR.instances[field];
					sent_data[$('#'+field).attr('name')] = encodeURIComponent(editor.getData());
		  		});
			}
		}
	
		///////////////////////
		// Отсылаем АЯКС запрос
		$.ajax({
			url: $('#'+id).attr('action'),
			type: $('#'+id).attr('method'),
			beforeSend: ongDefaultHandler[beforeHandlerFunc], 
			dataType: "json",
			data: sent_data,
			success: function() { var args = Array.prototype.slice.call(arguments); args.unshift('success', successHandlerFunc); ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder.apply(null, args); },
			error: showErrorRedirect,
			complete: ongDefaultHandler[afterHandlerFunc]
	  	});
	}
	
	return false; 
}

/////////////////////////////////////////////////////////////////////////////
// Функция вывода сообщений о возникших ошибках во время выполнения запросов.
function showErrorRedirect(reqParams, textStatus, responseText, xUID, xClass)
{
	//////////////////////////////////////////////////////
	// Проверяем, что были переданы необходимые параметры.
	// Если не передан UID диалогового окна, то используем стандартный UID "dialogWinBlock".
	if (typeof(xUID) == 'undefined' || xUID == '') { var xUID = 'dialogWinBlock'; }
	// Если не передан идентификатор класса диалогового окна, то используем стандартный класс "dialog_content_center".
	if (typeof(xClass) == 'undefined' || xClass == '') { var xClass = 'dialog_content_center'; }

	/////////////////////////////////////////
	// Формируем содержимое диалогового окна.
	var dialogContent = '<div class="dialogMessageBlock">'+responseText+'</div><div class="dialogButtonsBlock"><input class="dialogButton" type="button" value="Закрыть" onclick="javascript:ongDefaultHandler.ongServiceFuncs.ongRedirect(\''+ongDefaultHandler.ongCurrSiteLink+'\')" /></div>';

	///////////////////////////////////////////////////////////////////////////////////////
	// Определяем callback-функцию, выполняемую по завершению формирования модального окна.
	var callbackFunc = function() 
	{ 
		/////////////////////////////////////////////////////////
		// Добавляем событие на кнопку закрытия диалогового окна.
		$('#dialogWinBlock').bind('dialogclose', function() { ongDefaultHandler.ongServiceFuncs.ongRedirect(ongDefaultHandler.ongCurrSiteLink); });

		/////////////////////////////
		// Открываем диалоговое окно.
		openDialogWinBlockStandart(xUID);
	}

	////////////////////////////////////////////////////////////////////
	// Определяем конфигурацию формируемого модального окна для запроса.
	var dialogWindowConfs = 
	{
		'xContent': 	dialogContent, 
		'xTitle': 		'Ошибка', 
		'xWidth': 		450, 
		'xHeight': 		'auto', 
		'xUID': 		xUID, 
		'xClasses': 	[xClass],
		'callback': 	callbackFunc
	};

	/////////////////////////////////////////
	// Формируем диалоговое окно с контентом.
	if (!createDialogWinBlockStandart(dialogWindowConfs)) { return false; }
}

///////////////////////////////////////////////////////////////////////////////////////////
// Функция отображающая ошибку в информационном окне без переадрессации на другую страницу.
// xUID			- ID контейнера для диалогового окна (необязательный параметр, значение по умолчанию - dialogWinBlock)
function showError(message, xUID, xClass)
{
	//////////////////////////////////////////////////////
	// Проверяем, что были переданы необходимые параметры.
	// Если не передан UID диалогового окна, то используем стандартный UID "dialogWinBlock".
	if (typeof(xUID) == 'undefined' || xUID == '') { var xUID = 'dialogWinBlock'; }

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если не передан идентификатор класса диалогового окна, то используем стандартный класс "dialog_content_center".
	if (typeof(xClass) == 'undefined' || xClass == '') { var xClass = 'dialog_content_center'; }	

	/////////////////////////////////////////
	// Формируем содержимое диалогового окна.
	var dialogContent = '<div class="dialogMessageBlock">'+message+'</div><div class="dialogButtonsBlock"><input class="dialogButton" type="button" value="Закрыть" onclick="javascript:closeDialogWinBlockStandart(\''+xUID+'\')" /></div>';

	///////////////////////////////////////////////////////////////////////////////////////
	// Определяем callback-функцию, выполняемую по завершению формирования модального окна.
	var callbackFunc = function() { openDialogWinBlockStandart(xUID); }

	////////////////////////////////////////////////////////////////////
	// Определяем конфигурацию формируемого модального окна для запроса.
	var dialogWindowConfs = 
	{
		'xContent': 	dialogContent, 
		'xTitle': 		'Ошибка', 
		'xWidth': 		450, 
		'xHeight': 		'auto', 
		'xUID': 		xUID, 
		'xClasses': 	[xClass],
		'callback': 	callbackFunc
	};

	/////////////////////////////////////////
	// Формируем диалоговое окно с контентом.
	if (!createDialogWinBlockStandart(dialogWindowConfs)) { return false; }
}

////////////////////////////////////////////////////
// Функция формирования диалогового окна (основная):
// Принимаются следующие параметры:
// xContent		- Содержимое диалогового окна (обязательный параметр)
// xTitle		- Название диалогового окна (необязательный параметр, значение по умолчанию - Сообщение)
// xWidth		- Ширина диалогового окна (необязательный параметр, значение по умолчанию - 400)
// xHeight		- Высота диалогового окна (необязательный параметр, значение по умолчанию - auto)
// xUID			- ID контейнера для диалогового окна (необязательный параметр, значение по умолчанию - dialogWinBlock)
// xClasses		- Массив со списком дополнительных классов контейнера диалогового окна (необязательный параметр, по умолчанию пуст)
// xPosX		- Позиционирование диалогового окна по горизонтали (необязательный параметр, значение по умолчанию - center )
// xPosY		- Позиционирование диалогового окна по вертикали (необязательный параметр, значение по умолчанию - 150 )
// callback 	- анонимная callback-функция, выполняемая после успешного завершения формирования диалогового окна.
function createDialogWinBlockStandart(winConfs)
{
	//////////////////////////////////////////////////////
	// Проверяем, что были переданы необходимые параметры.
	// Если не передан UID диалогового окна, то используем стандартный UID "dialogWinBlock".
	if (typeof(winConfs) != 'object') { return false; }
	if (typeof(winConfs.xContent) == 'undefined' || winConfs.xContent == '') { return false; }
	if (typeof(winConfs.xTitle) == 'undefined' || winConfs.xTitle == '') { winConfs.xTitle = ''; }
	if (typeof(winConfs.xWidth) == 'undefined' || winConfs.xWidth == '') { winConfs.xWidth = 450; }
	if (typeof(winConfs.xHeight) == 'undefined' || winConfs.xHeight == '') { winConfs.xHeight = 'auto'; }
	if (typeof(winConfs.xUID) == 'undefined' || winConfs.xUID == '') { winConfs.xUID = 'dialogWinBlock'; }
	if (typeof(winConfs.xPosX) == 'undefined' || winConfs.xPosX == '') { winConfs.xPosX = 'center'; }
	if (typeof(winConfs.xPosY) == 'undefined' || winConfs.xPosY == '') { winConfs.xPosY = 150; }

	/////////////////////////////////////
	// Определяем флаг результата работы.
	var resultFlag = '';

	///////////////////////////////////////////////////////////////////////////////////////
	// Определяем существование див элемента с указанным UID. Если его нет, то создаем его.
	// Если диалоговое окно уже существует, то уничтожаем его и создаем новое.
	if ($("#"+winConfs.xUID).length > 0)
	{
		////////////////////////////////////////////////////////////////
		// Проверяем был-ли к элементу применен плагин диалогового окна.
		if ($("#"+winConfs.xUID).hasClass('ui-dialog-content')) 
		{
			//////////////////////////////////////////////////
			// Если диалоговое окно открыто, то закрываем его.
			if ($("#"+winConfs.xUID).dialog("isOpen")) 
			{
				/////////////////////////////
				// Закрываем диалоговое окно.
				$("#"+winConfs.xUID).dialog("close");
				
				////////////////////////////////////////////////////////////////////////////////////////////////////
				// Определяем отложенную функцию по завершению запроса на формирование содержимого диалогового окна.
				// Это необходимо для успешной отработки анимации по закрытию текущего диалогового окна и корректного удаления со страницы всех 
				// дополнительных div контейнеров создаваемых плугином JqueryUI. Иначе, при немедленном уничтожении контейнера диалогового окна, 
				// на странице остаются невидимые div контейнеры располагающиеся поверх всех остальных окон и препятствующие на доступ к элементам сайта под ними.
				setTimeout(function()
				{
					//////////////////////////////////////////////////////////////
					// Уничтожаем диалоговое окно, если оно было инициализировано.
					$("#"+winConfs.xUID).dialog("destroy");
					
					///////////////////////////
					// Уничтожаем div эелемент.
					$("#"+winConfs.xUID).remove();

					/////////////////////////////////////////////////////////////////////////
					// Производим вызов дополнительной функции формирования диалогового окна.					
					createDialogWinBlockStandartAdditionalRequest(winConfs);
					
				}, 600);
				
				//////////////////////////////////////////////////////////////////////////
				// Возвращаем false, ознаяающее, что диалоговое окно не было сформировано.
				// Это необходимо для исключения двойного запроса на отображение диалогового окна.
				return false;
			}
		}
		
		///////////////////////////
		// Уничтожаем div эелемент.
		$("#"+winConfs.xUID).remove();
	}

	/////////////////////////////////////////////////////////////////////////
	// Производим вызов дополнительной функции формирования диалогового окна.
	resultFlag = createDialogWinBlockStandartAdditionalRequest(winConfs);
	return resultFlag;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Функция формирования диалогового окна (дополнительная). Принимает те же самые параметры, что и основная функция.
function createDialogWinBlockStandartAdditionalRequest(winConfs)
{
	//////////////////////////////////////////////////////
	// Проверяем, что были переданы необходимые параметры.
	if (typeof(winConfs) != 'object') { return false; }
	if (typeof(winConfs.xContent) == 'undefined' || winConfs.xContent == '') { return false; }
	if (typeof(winConfs.xTitle) == 'undefined' || winConfs.xTitle == '') { winConfs.xTitle = ''; }
	if (typeof(winConfs.xWidth) == 'undefined' || winConfs.xWidth == '') { winConfs.xWidth = 450; }
	if (typeof(winConfs.xHeight) == 'undefined' || winConfs.xHeight == '') { winConfs.xHeight = 'auto'; }
	if (typeof(winConfs.xUID) == 'undefined' || winConfs.xUID == '') { winConfs.xUID = 'dialogWinBlock'; }
	if (typeof(winConfs.xPosX) == 'undefined' || winConfs.xPosX == '') { winConfs.xPosX = 'center'; }
	if (typeof(winConfs.xPosY) == 'undefined' || winConfs.xPosY == '') { winConfs.xPosY = 150; }	
	if (typeof(winConfs.autoOpen) == 'undefined' || winConfs.autoOpen == '') { winConfs.autoOpen = 0; }	
	
	//////////////////////////////////////////
	// Формируем div элемент диалогового окна.
	var dialogBlock = $('<div>', { id: winConfs.xUID });
	$('body').append(dialogBlock);
	
	/////////////////////////////////////////////////////////
	// Определяем диалоговое окно через библиотеку JQuery UI.
	$("#"+winConfs.xUID).dialog(
	{
		position: { my: "center", at: "center", of: window },
		autoOpen: false,
		open: function(){
			$('.ui-widget-overlay').hide().fadeIn();
		},
		beforeClose: function()
		{
			//////////////////////////////////////////////////////////////////////////////
			// Если массив с данными по редакторам CKeditor для удаляемого окна не пустой,
			// производим предварительное уничтожение редакторов CKeditor из текущего модального окна.
			if (typeof(ongDefaultHandler.ongCkeditorInstances[winConfs.xUID]) != 'undefined') 
			{
				$.each(ongDefaultHandler.ongCkeditorInstances[winConfs.xUID], function (field, value) 
				{
					ongDefaultHandler.ongServiceFuncs.ongRemoveCKEditor(value);
				});
				
				/////////////////////////////////////////////////////////////////////////
				// Обнуляем список активных редакторов CKeditor в текущем модальном окне.
				ongDefaultHandler.ongCkeditorInstances[winConfs.xUID] = [];
			}
		},
		hide: {effect: "drop", direction: "down", duration: 300},
		show: {effect: "drop", direction: "up",  duration: 300}
	});
	
	/////////////////////////////////////////////////////
	// Устанавливаем основные параметры диалогового окна.
	$('#'+winConfs.xUID).dialog('option', 'width', winConfs.xWidth);
	$('#'+winConfs.xUID).dialog('option', 'height', winConfs.xHeight);
	$('#'+winConfs.xUID).dialog('option', 'title', winConfs.xTitle);
	$('#'+winConfs.xUID).empty().prepend(winConfs.xContent);
	
	///////////////////////////////////////////////
	// Определяем диалоговое окно в модальное окно.
	$('#'+winConfs.xUID).dialog('option', 'modal', true);
	
	//////////////////////
	// Позиционируем окно:
	var atPosStrX = 'center;', atPosStrY = 'center;';
	
	if (typeof(winConfs.xPosX) == 'number') { winConfs.xPosX = 'left+'+winConfs.xPosX; atPosStrX = 'left';  }
	else if (typeof(winConfs.xPosX) != 'string') { winConfs.xPosX = 'center'; }
	
	if (typeof(winConfs.xPosY) == 'number') { winConfs.xPosY = 'top+'+winConfs.xPosY; atPosStrY = 'top' }
	else if (typeof(winConfs.xPosY) != 'string') { winConfs.xPosY = 'center'; }

	$('#'+winConfs.xUID).dialog('option', 'position', { my: winConfs.xPosX+" "+winConfs.xPosY, at: atPosStrX+" "+atPosStrY, collision: 'fit', of: window } );
	
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если был передан параметр xClasses и он является массивом, то добавляем контейнеру диалогового окна дополнительные классы.
	if (typeof(winConfs.xClasses) != 'undefined' && winConfs.xClasses != '' && winConfs.xClasses instanceof Array) 
	{
		$.each(winConfs.xClasses, function(i, field) { $('#'+winConfs.xUID).addClass(field); });		
	}

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если в конфигурационных параметрах была передана анонимная функция callback-запроса, то перед завершением работы выполняем ее.
	if (typeof(winConfs.callback) == 'function') { winConfs.callback(); }

	/////////////////////////////////////////////////////////////////////////////////
	// Возвращаем значение true, информация об успешно сформированом диалоговом окне.
	return true;
}

////////////////////////////////////////
// Функция отображающая диалоговое окно.
function openDialogWinBlockStandart(xUID)
{
	////////////////////////////////////////////////////////////////////////////////////////
	// Если не передан UID диалогового окна, то используем стандартный UID "dialogWinBlock".
	if (typeof(xUID) == 'undefined' || xUID == '') { var xUID = 'dialogWinBlock'; }
	
	////////////////////////////////////////////////////////////////////////////////////////////////////
	// Определяем существование див элемента с указанным UID и было ли инициализировано диалоговое окно.
	if ($("#"+xUID).length > 0)
	{
		////////////////////////////////////////////////////////////////
		// Проверяем был-ли к элементу применен плагин диалогового окна.
		if ($("#"+xUID).hasClass('ui-dialog-content')) 
		{
			// Открываем диалоговое окно
			$('#'+xUID).dialog('open');
			// Помещаем диалоговое окно поверх других диалоговых окон.
			$("#"+xUID).dialog("moveToTop");
			// Возвращаем значение true, информация об успешно открытом диалоговом окне.
			return true;
		}
	}
	
	///////////////////////////////////////////////////////////////////////////////////////
	// Возвращаем значение false, информация о том, что диалоговое окно открыть не удалось.
	return false;
}

///////////////////////////////////////
// Функция закрывающая диалоговое окно.
function closeDialogWinBlockStandart(xUID) 
{
	////////////////////////////////////////////////////////////////////////////////////////
	// Если не передан UID диалогового окна, то используем стандартный UID "dialogWinBlock".
	if (typeof(xUID) == 'undefined' || xUID == '') { var xUID = 'dialogWinBlock'; }
	
	////////////////////////////////////////////////////////////////////////////////////////////////////
	// Определяем существование див элемента с указанным UID и было ли инициализировано диалоговое окно.
	if ($("#"+xUID).length > 0)
	{
		////////////////////////////////////////////////////////////////
		// Проверяем был-ли к элементу применен плагин диалогового окна.
		if ($("#"+xUID).hasClass('ui-dialog-content')) 
		{
			//////////////////////////////////////////////////
			// Если диалоговое окно открыто, то закрываем его.
			if ($("#"+xUID).dialog("isOpen")) { $("#"+xUID).dialog("close"); }
		}
	}
	
	/////////////////////////////////////////////////////////////////////////////
	// Возвращаем значение true, информация об успешно озакрытом диалоговом окне.
	return true;
}

/////////////////////////////////////////////////////////////////////////////////////////////////
// ФУНКЦИЯ-ОБОЛОЧКА ДЛЯ ФУНКЦИЙ ОБРАБОТКИ ЗАПРОСОВ ОТ КНОПОК УПРАВЛЕНИЯ ДРУЖЕСТВЕННАЯ К СОБЫТИЯМ.
// Принимает параметры:
//	event 		- событие
//	func 		- имя запрашиваемой функции-обработчика запросов от кнопок.
//	ongac 		- идентификатор запрашиваемого действия.
//	sendVals 	- объект с данными для передачи (необязательный).
//	confs 		- объект с конфигурационными данными (необязательный).
function defaultButtonRequestEventFriendly(event, func, ongac, sendVals, confs)
{
	/////////////////////////////////////////////////////////////////////////////////////////
	// Обрабатываем необходимость отмены стандартного действия и/или отмены всплытия события.
	if (typeof(confs.reqPreventDefault) != 'undefined' && confs.reqPreventDefault) { event.preventDefault(); }
	if (typeof(confs.reqStopPropagation) != 'undefined' && confs.reqStopPropagation) { event.stopPropagation(); }

	////////////////////////////////////////////
	// Передаем управление родительскому методу.
	if (typeof(window[func]) == 'function')
	{
		window[func](ongac, sendVals, confs);
	}
	else
	{
		$().toastmessage('showToast', { text: 'Не обнаружен указанный обработчик запросов от кнопок!', sticky: false, position: 'top-left', type: 'error' }); return false;
	}
}

///////////////////////////////////////////////////////////////
// СТАНДАРТНАЯ ФУНКЦИЯ ОБРАБОТКИ ЗАПРОСОВ ОТ КНОПОК УПРАВЛЕНИЯ.
// Принимает три параметра:
//	- Идентификатор запрашиваемого действия.
//	- Объект с данными для передачи (необязательный).
//	- Объект с конфигурационными данными (необязательный).
function defaultButtonRequest(ongac, sendVals, confs)
{
	//////////////////////////////////////////////////////////////////////////
	// Если не передан идентификатор действия, то выводим сообщение об ошибке.
	if (typeof(ongac) == 'undefined' || ongac == '')
	{
		showError('Не передан идентификатор действия!');
		return false;
	}

	/////////////////////////////////
	// Определяем рабочие переменные:
	var reqUrl = ongDefaultHandler.ongCurrSiteLink,
		reqType = "GET",
		reqBeforeSend = 'ongActivateAjaxIcon',
		reqDataType = 'json',
		reqSuccess = 'ongDefaultRequestResultShow',
		reqError = 'showErrorRedirect',
		reqComplete = 'ongDeactivateAjaxIcon',
		reqCheckMultiList = false,
		reqSentData = {};

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если передан объект конфигурационных данных, то проверяем его и при необходимости переопределяем значение рабочих переменных.
	if (typeof(confs) == 'object')
	{
		if (typeof(confs.reqUrl) != 'undefined' && confs.reqUrl != '') { reqUrl = confs.reqUrl; }
		if (typeof(confs.reqType) != 'undefined' && confs.reqType != '') { reqType = confs.reqType; }
		if (typeof(confs.reqBeforeSend) != 'undefined' && confs.reqBeforeSend != '') { reqBeforeSend = confs.reqBeforeSend; }
		if (typeof(confs.reqDataType) != 'undefined' && confs.reqDataType != '') { reqDataType = confs.reqDataType; }
		if (typeof(confs.reqSuccess) != 'undefined' && confs.reqSuccess != '') { reqSuccess = confs.reqSuccess; }
		if (typeof(confs.reqError) != 'undefined' && confs.reqError != '') { reqError = confs.reqError; }
		if (typeof(confs.reqComplete) != 'undefined' && confs.reqComplete != '') { reqComplete = confs.reqComplete; }
		if (typeof(confs.reqCheckMultiList) != 'undefined' && confs.reqCheckMultiList != '') { reqCheckMultiList = confs.reqCheckMultiList; }
	}

	////////////////////////////////////////////////////////////////
	// Добавляем в переменную передачи данных стандартные параметры:
	reqSentData['ongac'] = ongac;
	reqSentData['isAjaxRequest'] = 1;

	//////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если передан объект параметров для передачи, то добавляем их в соответствующую переменную для передачи.
	if (typeof(sendVals) == 'object')
	{
		$.each(sendVals, function(index, value) 
		{
		    reqSentData[index] = value;
		}); 
	}

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Если определен флаг необходимости поиска и подключения к запросу массива из списка выделенных чекбоксов обрабатываемого списка параметров, 
	// то производим поиск сответствующих условию чекбоксов и добавляем их в запрос.
	if (reqCheckMultiList && $('input.'+reqCheckMultiList+'[type="checkbox"]').length > 0)
	{
		//////////////////////////////////////////////////////
		// Определяем массив для списка выделенных параметров.
		var ongCheckboxes = $('input.'+reqCheckMultiList+'[type="checkbox"]');
		reqSentData['checkMultiList'] = [];

		for (var i = 0; i < ongCheckboxes.length; i++) 
		{
			if (ongCheckboxes[i].checked) 
			{
        		reqSentData['checkMultiList'].push(ongCheckboxes[i].value);
     		}		
     	}
	}

	//////////////////////////
	// Производим AJAX-запрос.
	$.ajax(
	{
		url: reqUrl,
		type: reqType,
		beforeSend: function() { var args = Array.prototype.slice.call(arguments); args.unshift('beforeSend', reqBeforeSend); ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder.apply(null, args); },
		dataType: reqDataType,
		data: reqSentData,
		success: function() { var args = Array.prototype.slice.call(arguments); args.unshift('success', reqSuccess); ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder.apply(null, args); },
		error: window[reqError],
		complete: function() { var args = Array.prototype.slice.call(arguments); args.unshift('complete', reqComplete); ongDefaultHandler.ongAjaxRequestsFuncs.ongHandlerFinder.apply(null, args); }
   	});
	
	////////////////////////////
	// Завершаем работу функции.
	return false;
}

////////////////////////////////////////////
// Расширение для генерации случайных чисел.
// Copyright - Unknown
// Author: Unknown
jQuery.extend(
{
	random: function(X) 
	{
	    return Math.floor(X * (Math.random() % 1));
	},
	randomBetween: function(MinV, MaxV) 
	{
	  return MinV + jQuery.random(MaxV - MinV + 1);
	}
});

///////////////////////////////////////////////////////////
// Расширение библиотеки Jquery. Aeyrwbz silent сообщений.
// Copyright 2010 akquinet
// Author: Daniel Bremer-Tonn
/**
 *  The following methods will display a toast message:
 *   $().toastmessage('showNoticeToast', 'some message here');
 *   $().toastmessage('showSuccessToast', "some message here");
 *   $().toastmessage('showWarningToast', "some message here");
 *   $().toastmessage('showErrorToast', "some message here");
 *
 *   // user configured toastmessage:
 *   $().toastmessage('showToast', {
 *      text     : 'Hello World',
 *      sticky   : true,
 *      position : 'top-right',
 *      type     : 'success',
 *      close    : function () {console.log("toast is closed ...");}
 *   });
 */
(function($)
{
	var settings = 
	{
		inEffect: {opacity: 'show'}, // in effect
		inEffectDuration: 600, // in effect duration in miliseconds
		stayTime: 6000,	// time in miliseconds before the item has to disappear
		text: '', // content of the item. Might be a string or a jQuery object. Be aware that any jQuery object which is acting as a message will be deleted when the toast is fading away.
		sticky: false, // should the toast item sticky or not?
		type: 'notice', // notice, warning, error, success
        position: 'top-right', // top-left, top-center, top-right, middle-left, middle-center, middle-right ... Position of the toast container holding different toast. Position can be set only once at the very first call, changing the position after the first call does nothing
        closeText: '', // text which will be shown as close button, set to '' when you want to introduce an image via css
        close: null // callback function when the toastmessage is closed
	};

    var methods = 
	{
		init : function(options)
		{
			if (options) 
			{
                $.extend( settings, options );
            }
		},

        showToast : function(options)
		{
			var localSettings = {};
            $.extend(localSettings, settings, options);

			// declare variables
            var toastWrapAll, toastItemOuter, toastItemInner, toastItemClose, toastItemImage;

			toastWrapAll = (!$('.toast-container').length) ? $('<div></div>').addClass('toast-container').addClass('toast-position-' + localSettings.position).appendTo('body') : $('.toast-container');
			toastItemOuter = $('<div></div>').addClass('toast-item-wrapper');
			toastItemInner = $('<div></div>').hide().addClass('toast-item toast-type-' + localSettings.type).appendTo(toastWrapAll).html($('<p>').append (localSettings.text)).animate(localSettings.inEffect, localSettings.inEffectDuration).wrap(toastItemOuter);
			toastItemClose = $('<div></div>').addClass('toast-item-close').prependTo(toastItemInner).html(localSettings.closeText).click(function() { $().toastmessage('removeToast',toastItemInner, localSettings) });
			toastItemImage  = $('<div></div>').addClass('toast-item-image').addClass('toast-item-image-' + localSettings.type).prependTo(toastItemInner);

            if(navigator.userAgent.match(/MSIE 6/i))
			{
		    	toastWrapAll.css({top: document.documentElement.scrollTop});
		    }

			if(!localSettings.sticky)
			{
				setTimeout(function()
				{
					$().toastmessage('removeToast', toastItemInner, localSettings);
				},
				localSettings.stayTime);
			}
            return toastItemInner;
		},

        showNoticeToast : function (message)
        {
            var options = {text : message, type : 'notice'};
            return $().toastmessage('showToast', options);
        },

        showSuccessToast : function (message)
        {
            var options = {text : message, type : 'success'};
            return $().toastmessage('showToast', options);
        },

        showErrorToast : function (message)
        {
            var options = {text : message, type : 'error'};
            return $().toastmessage('showToast', options);
        },

        showWarningToast : function (message)
        {
            var options = {text : message, type : 'warning'};
            return $().toastmessage('showToast', options);
        },

		removeToast: function(obj, options)
		{
			obj.animate({opacity: '0'}, 600, function()
			{
				obj.parent().animate({height: '0px'}, 300, function()
				{
					obj.parent().remove();
				});
			});
            // callback
            if (options && options.close !== null)
            {
                options.close();
            }
		}
	};

    $.fn.toastmessage = function( method ) {

        // Method calling logic
        if ( methods[method] ) 		
		{
          return methods[ method ].apply( this, Array.prototype.slice.call( arguments, 1 ));
        } 
		else if ( typeof method === 'object' || ! method ) 
		{
          return methods.init.apply( this, arguments );
        } 
		else 
		{
          $.error( 'Method ' +  method + ' does not exist on jQuery.toastmessage' );
        }
    };

})(jQuery);

!function() {
	var re = {
		not_string: /[^s]/,
		not_bool: /[^t]/,
		not_type: /[^T]/,
		not_primitive: /[^v]/,
		number: /[diefg]/,
		numeric_arg: /[bcdiefguxX]/,
		json: /[j]/,
		not_json: /[^j]/,
		text: /^[^\x25]+/,
		modulo: /^\x25{2}/,
		placeholder: /^\x25(?:([1-9]\d*)\$|\(([^\)]+)\))?(\+)?(0|'[^$])?(-)?(\d+)?(?:\.(\d+))?([b-gijostTuvxX])/,
		key: /^([a-z_][a-z_\d]*)/i,
		key_access: /^\.([a-z_][a-z_\d]*)/i,
		index_access: /^\[(\d+)\]/,
		sign: /^[\+\-]/
	}

	function sprintf(key) {
		// `arguments` is not an array, but should be fine for this call
		return sprintf_format(sprintf_parse(key), arguments)
	}

	function vsprintf(fmt, argv) {
		return sprintf.apply(null, [fmt].concat(argv || []))
	}

	function sprintf_format(parse_tree, argv) {
		var cursor = 1, tree_length = parse_tree.length, arg, output = '', i, k, ph, pad, pad_character, pad_length, is_positive, sign
		for (i = 0; i < tree_length; i++) {
			if (typeof parse_tree[i] === 'string') {
				output += parse_tree[i]
			}
			else if (typeof parse_tree[i] === 'object') {
				ph = parse_tree[i] // convenience purposes only
				if (ph.keys) { // keyword argument
					arg = argv[cursor]
					for (k = 0; k < ph.keys.length; k++) {
						if (!arg.hasOwnProperty(ph.keys[k])) {
							throw new Error(sprintf('[sprintf] property "%s" does not exist', ph.keys[k]))
						}
						arg = arg[ph.keys[k]]
					}
				}
				else if (ph.param_no) { // positional argument (explicit)
					arg = argv[ph.param_no]
				}
				else { // positional argument (implicit)
					arg = argv[cursor++]
				}

				if (re.not_type.test(ph.type) && re.not_primitive.test(ph.type) && arg instanceof Function) {
					arg = arg()
				}

				if (re.numeric_arg.test(ph.type) && (typeof arg !== 'number' && isNaN(arg))) {
					throw new TypeError(sprintf('[sprintf] expecting number but found %T', arg))
				}

				if (re.number.test(ph.type)) {
					is_positive = arg >= 0
				}

				switch (ph.type) {
					case 'b':
						arg = parseInt(arg, 10).toString(2)
						break
					case 'c':
						arg = String.fromCharCode(parseInt(arg, 10))
						break
					case 'd':
					case 'i':
						arg = parseInt(arg, 10)
						break
					case 'j':
						arg = JSON.stringify(arg, null, ph.width ? parseInt(ph.width) : 0)
						break
					case 'e':
						arg = ph.precision ? parseFloat(arg).toExponential(ph.precision) : parseFloat(arg).toExponential()
						break
					case 'f':
						arg = ph.precision ? parseFloat(arg).toFixed(ph.precision) : parseFloat(arg)
						break
					case 'g':
						arg = ph.precision ? String(Number(arg.toPrecision(ph.precision))) : parseFloat(arg)
						break
					case 'o':
						arg = (parseInt(arg, 10) >>> 0).toString(8)
						break
					case 's':
						arg = String(arg)
						arg = (ph.precision ? arg.substring(0, ph.precision) : arg)
						break
					case 't':
						arg = String(!!arg)
						arg = (ph.precision ? arg.substring(0, ph.precision) : arg)
						break
					case 'T':
						arg = Object.prototype.toString.call(arg).slice(8, -1).toLowerCase()
						arg = (ph.precision ? arg.substring(0, ph.precision) : arg)
						break
					case 'u':
						arg = parseInt(arg, 10) >>> 0
						break
					case 'v':
						arg = arg.valueOf()
						arg = (ph.precision ? arg.substring(0, ph.precision) : arg)
						break
					case 'x':
						arg = (parseInt(arg, 10) >>> 0).toString(16)
						break
					case 'X':
						arg = (parseInt(arg, 10) >>> 0).toString(16).toUpperCase()
						break
				}
				if (re.json.test(ph.type)) {
					output += arg
				}
				else {
					if (re.number.test(ph.type) && (!is_positive || ph.sign)) {
						sign = is_positive ? '+' : '-'
						arg = arg.toString().replace(re.sign, '')
					}
					else {
						sign = ''
					}
					pad_character = ph.pad_char ? ph.pad_char === '0' ? '0' : ph.pad_char.charAt(1) : ' '
					pad_length = ph.width - (sign + arg).length
					pad = ph.width ? (pad_length > 0 ? pad_character.repeat(pad_length) : '') : ''
					output += ph.align ? sign + arg + pad : (pad_character === '0' ? sign + pad + arg : pad + sign + arg)
				}
			}
		}
		return output
	}

	var sprintf_cache = Object.create(null)

	function sprintf_parse(fmt) {
		if (sprintf_cache[fmt]) {
			return sprintf_cache[fmt]
		}

		var _fmt = fmt, match, parse_tree = [], arg_names = 0
		while (_fmt) {
			if ((match = re.text.exec(_fmt)) !== null) {
				parse_tree.push(match[0])
			}
			else if ((match = re.modulo.exec(_fmt)) !== null) {
				parse_tree.push('%')
			}
			else if ((match = re.placeholder.exec(_fmt)) !== null) {
				if (match[2]) {
					arg_names |= 1
					var field_list = [], replacement_field = match[2], field_match = []
					if ((field_match = re.key.exec(replacement_field)) !== null) {
						field_list.push(field_match[1])
						while ((replacement_field = replacement_field.substring(field_match[0].length)) !== '') {
							if ((field_match = re.key_access.exec(replacement_field)) !== null) {
								field_list.push(field_match[1])
							}
							else if ((field_match = re.index_access.exec(replacement_field)) !== null) {
								field_list.push(field_match[1])
							}
							else {
								throw new SyntaxError('[sprintf] failed to parse named argument key')
							}
						}
					}
					else {
						throw new SyntaxError('[sprintf] failed to parse named argument key')
					}
					match[2] = field_list
				}
				else {
					arg_names |= 2
				}
				if (arg_names === 3) {
					throw new Error('[sprintf] mixing positional and named placeholders is not (yet) supported')
				}

				parse_tree.push(
					{
						placeholder: match[0],
						param_no:    match[1],
						keys:        match[2],
						sign:        match[3],
						pad_char:    match[4],
						align:       match[5],
						width:       match[6],
						precision:   match[7],
						type:        match[8]
					}
				)
			}
			else {
				throw new SyntaxError('[sprintf] unexpected placeholder')
			}
			_fmt = _fmt.substring(match[0].length)
		}
		return sprintf_cache[fmt] = parse_tree
	}

	/**
	 * export to either browser or node.js
	 */
	/* eslint-disable quote-props */
	if (typeof exports !== 'undefined') {
		exports['sprintf'] = sprintf
		exports['vsprintf'] = vsprintf
	}
	if (typeof window !== 'undefined') {
		window['sprintf'] = sprintf
		window['vsprintf'] = vsprintf

		if (typeof define === 'function' && define['amd']) {
			define(function() {
				return {
					'sprintf': sprintf,
					'vsprintf': vsprintf
				}
			})
		}
	}
}();